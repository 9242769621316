import React, { useState } from 'react';
import { Search } from 'lucide-react';

interface HSCodeAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
}

const HSCodeAutocomplete: React.FC<HSCodeAutocompleteProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const suggestions = [
    { code: '8544.42', description: 'Electric conductors, for a voltage <= 1,000 V, fitted with connectors' },
    { code: '8501.10', description: 'Electric motors of an output <= 37.5 W' },
    { code: '8536.90', description: 'Electrical apparatus for switching electrical circuits' },
  ];

  return (
    <div className="relative">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-lg border border-gray-300 pl-10 pr-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Search by HS Code or product description..."
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 border border-gray-200">
          {suggestions.map((suggestion) => (
            <button
              key={suggestion.code}
              className="w-full px-4 py-2 text-left hover:bg-gray-50"
              onClick={() => {
                onChange(suggestion.code);
                setIsOpen(false);
              }}
            >
              <div className="flex items-start">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                  {suggestion.code}
                </span>
                <span className="ml-2 text-sm text-gray-600">{suggestion.description}</span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default HSCodeAutocomplete;