import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'implicit',
    storage: localStorage,
    storageKey: 'supabase.auth.token',
    debug: import.meta.env.DEV
  },
  global: {
    headers: {
      'X-Client-Info': 'supabase-js-web/2.39.7'
    }
  },
  realtime: {
    params: {
      eventsPerSecond: 2 // Reduced to help prevent rate limiting
    }
  }
});

// Initialize auth state
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
    localStorage.removeItem('supabase.auth.token');
    sessionStorage.clear();
  }
});