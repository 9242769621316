import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Search, 
  TrendingUp, 
  Users,
  Pin,
  PinOff,
  Settings,
  Ship,
  History,
  Bookmark,
  LayoutDashboard,
  Factory,
  Mail,
  Target,
  FileText,
  Crosshair
} from 'lucide-react';

interface NavItem {
  title: string;
  icon: React.ElementType;
  path: string;
  category: string;
}

const navItems: NavItem[] = [
  // Dashboard
  { title: 'Dashboard', icon: LayoutDashboard, path: '/dashboard', category: 'General' },
  
  // Search Tools
  { title: 'Buyer Search', icon: Search, path: '/buyer-search', category: 'Search Tools' },
  { title: 'Supplier Search', icon: Factory, path: '/supplier-search', category: 'Search Tools' },
  { title: 'Competitor Tracker', icon: Crosshair, path: '/competitor-tracker', category: 'Search Tools' },
  { title: 'Market Trends', icon: TrendingUp, path: '/buyer-trends', category: 'Search Tools' },
  
  // Outreach Tools
  { title: 'Outreach Campaigns', icon: Mail, path: '/buyer-outreach', category: 'Outreach Tools' },
  { title: 'My Leads', icon: Target, path: '/my-leads', category: 'Outreach Tools' },
  
  // Data Management
  { title: 'Search History', icon: History, path: '/search-history', category: 'Data Management' },
  { title: 'HS Code List', icon: FileText, path: '/hs-codes', category: 'Data Management' },
  
  // Account
  { title: 'Settings', icon: Settings, path: '/settings', category: 'Account' }
];

const Sidebar: React.FC = () => {
  const [isPinned, setIsPinned] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const isExpanded = isPinned || isHovered;

  const categories = Array.from(new Set(navItems.map(item => item.category)));

  return (
    <div
      className={`fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white border-r border-gray-200 transition-all duration-300 ${
        isExpanded ? 'w-64' : 'w-16'
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-col h-full">
        <div className="p-4 border-b border-gray-200">
          <button
            onClick={() => setIsPinned(!isPinned)}
            className={`w-full flex items-center justify-center p-2 text-gray-500 hover:text-gray-900 ${
              isExpanded ? 'justify-between' : 'justify-center'
            }`}
          >
            {isExpanded && <span className="text-sm">Pin Sidebar</span>}
            {isPinned ? (
              <Pin className="h-4 w-4" />
            ) : (
              <PinOff className="h-4 w-4" />
            )}
          </button>
        </div>

        <div className="flex-1 overflow-y-auto py-4">
          {categories.map((category) => (
            <div key={category} className="mb-6">
              {isExpanded && (
                <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
                  {category}
                </h3>
              )}
              <nav className="space-y-1">
                {navItems
                  .filter(item => item.category === category)
                  .map((item) => {
                    const Icon = item.icon;
                    const isActive = location.pathname === item.path;
                    return (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`flex items-center px-4 py-2 text-sm font-medium ${
                          isActive
                            ? 'text-indigo-600 bg-indigo-50'
                            : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                        } ${!isExpanded ? 'justify-center' : ''}`}
                      >
                        <Icon className={`h-5 w-5 ${isActive ? 'text-indigo-600' : 'text-gray-400'}`} />
                        {isExpanded && <span className="ml-3">{item.title}</span>}
                      </Link>
                    );
                  })}
              </nav>
            </div>
          ))}
        </div>

        <div className="p-4 border-t border-gray-200">
          {isExpanded && (
            <div className="flex items-center space-x-3">
              <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
                <Ship className="h-4 w-4 text-indigo-600" />
              </div>
              <div className="text-sm">
                <p className="font-medium text-gray-900">7Y App</p>
                <p className="text-gray-500">v1.0.0</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;