import React, { useEffect, useRef } from 'react';
import { X, Filter, ChevronDown } from 'lucide-react';

interface FilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  type: 'buyer' | 'supplier';
}

const FilterPanel: React.FC<FilterPanelProps> = ({ isOpen, onClose, onApply, type }) => {
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleApply = () => {
    onApply();
    onClose();
  };

  return (
    <div
      ref={panelRef}
      className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="h-full flex flex-col">
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-medium text-gray-900">
              {type === 'buyer' ? 'Buyer Filters' : 'Supplier Filters'}
            </h2>
            <button
              onClick={onClose}
              className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto px-6 py-4">
          <div className="space-y-6">
            {/* Value Range */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-3">
                {type === 'buyer' ? 'Import Value Range' : 'Export Value Range'}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm text-gray-500 mb-1">Min Value</label>
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="0"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-500 mb-1">Max Value</label>
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="1000000"
                  />
                </div>
              </div>
            </div>

            {/* Size */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-3">
                {type === 'buyer' ? 'Buyer Size' : 'Supplier Size'}
              </h3>
              <div className="space-y-2">
                {['Small', 'Medium', 'Large', 'Enterprise'].map((size) => (
                  <label key={size} className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-600">{size}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Frequency */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-3">
                {type === 'buyer' ? 'Import Frequency' : 'Export Frequency'}
              </h3>
              <select className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                <option>Any frequency</option>
                <option>Weekly</option>
                <option>Monthly</option>
                <option>Quarterly</option>
                <option>Yearly</option>
              </select>
            </div>

            {/* Shipping Mode */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-3">Shipping Mode</h3>
              <div className="space-y-2">
                {['Sea', 'Air', 'Land'].map((mode) => (
                  <label key={mode} className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-600">{mode}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Partner Countries */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-3">
                {type === 'buyer' ? 'Source Countries' : 'Destination Countries'}
              </h3>
              <div className="relative">
                <select className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                  <option>All Countries</option>
                  <option>United States</option>
                  <option>China</option>
                  <option>Germany</option>
                  <option>Japan</option>
                </select>
                <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <button
              className="text-sm text-gray-600 hover:text-gray-900"
              onClick={() => {/* Reset filters */}}
            >
              Reset all filters
            </button>
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              onClick={handleApply}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;