import React, { useState } from 'react';
import { Plus, Trash2, Edit2 } from 'lucide-react';

interface Role {
  id: string;
  name: string;
  permissions: {
    [key: string]: {
      view: boolean;
      edit: boolean;
      use: boolean;
    };
  };
}

const defaultTabs = [
  'Dashboard',
  'Buyer Search',
  'Supplier Search',
  'Competitor Tracker',
  'Market Trends',
  'My Leads',
  'Campaigns',
  'Settings'
];

const AccessRoles = () => {
  const [roles, setRoles] = useState<Role[]>([
    {
      id: '1',
      name: 'Admin',
      permissions: defaultTabs.reduce((acc, tab) => ({
        ...acc,
        [tab]: { view: true, edit: true, use: true }
      }), {})
    }
  ]);

  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [newRoleName, setNewRoleName] = useState('');
  const [showNewRoleForm, setShowNewRoleForm] = useState(false);

  const handleAddRole = () => {
    if (!newRoleName.trim()) return;

    const newRole: Role = {
      id: Date.now().toString(),
      name: newRoleName,
      permissions: defaultTabs.reduce((acc, tab) => ({
        ...acc,
        [tab]: { view: false, edit: false, use: false }
      }), {})
    };

    setRoles([...roles, newRole]);
    setNewRoleName('');
    setShowNewRoleForm(false);
  };

  const handleDeleteRole = (roleId: string) => {
    if (window.confirm('Are you sure you want to delete this role?')) {
      setRoles(roles.filter(role => role.id !== roleId));
    }
  };

  const handlePermissionChange = (
    roleId: string,
    tab: string,
    permission: 'view' | 'edit' | 'use',
    value: boolean
  ) => {
    setRoles(roles.map(role => {
      if (role.id === roleId) {
        return {
          ...role,
          permissions: {
            ...role.permissions,
            [tab]: {
              ...role.permissions[tab],
              [permission]: value,
              // If edit or use is enabled, view must be enabled
              view: permission === 'view' ? value : (value || role.permissions[tab].view)
            }
          }
        };
      }
      return role;
    }));
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Access Roles</h3>
          <p className="mt-1 text-sm text-gray-500">
            Manage custom roles and their permissions
          </p>
        </div>
        <button
          onClick={() => setShowNewRoleForm(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Role
        </button>
      </div>

      {showNewRoleForm && (
        <div className="bg-gray-50 rounded-lg p-4">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <label htmlFor="newRoleName" className="block text-sm font-medium text-gray-700">
                Role Name
              </label>
              <input
                type="text"
                id="newRoleName"
                value={newRoleName}
                onChange={(e) => setNewRoleName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter role name"
              />
            </div>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                onClick={handleAddRole}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setShowNewRoleForm(false)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {roles.map((role) => (
          <div key={role.id} className="border-b border-gray-200 last:border-b-0">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-medium text-gray-900">{role.name}</h4>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setEditingRole(role)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  {role.name !== 'Admin' && (
                    <button
                      onClick={() => handleDeleteRole(role.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tab
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        View
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Edit
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Use
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {defaultTabs.map((tab) => (
                      <tr key={tab}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {tab}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={role.permissions[tab]?.view || false}
                            onChange={(e) => handlePermissionChange(role.id, tab, 'view', e.target.checked)}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            disabled={role.name === 'Admin'}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={role.permissions[tab]?.edit || false}
                            onChange={(e) => handlePermissionChange(role.id, tab, 'edit', e.target.checked)}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            disabled={role.name === 'Admin' || !role.permissions[tab]?.view}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={role.permissions[tab]?.use || false}
                            onChange={(e) => handlePermissionChange(role.id, tab, 'use', e.target.checked)}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            disabled={role.name === 'Admin' || !role.permissions[tab]?.view}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccessRoles;