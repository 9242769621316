import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuthStore } from '../store/auth';
import { signUp, createUserProfile } from '../auth/signupService';
import { handleAuthError } from '../utils/errorHandling';
import type { SignupData } from '../types/auth';
import { supabase } from '../config';

export const useAuth = () => {
  const navigate = useNavigate();
  const { setUser, setProfile, setLoading } = useAuthStore();

  const handleSignUp = useCallback(async (data: SignupData) => {
    setLoading(true);
    try {
      const { data: authData, error } = await signUp(data);
      
      if (error) throw error;
      
      if (authData.user) {
        await createUserProfile(authData.user.id, data);
        toast.success('Please check your email to verify your account');
        navigate('/verify-email', { state: { email: data.email } });
      }
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  }, [navigate, setLoading]);

  const signIn = useCallback(async (email: string, password: string) => {
    setLoading(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        await supabase.auth.signOut();
      }

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          redirectTo: `${window.location.origin}/dashboard`
        }
      });

      if (error) throw error;

      if (data?.user) {
        setUser(data.user);
        
        const { data: profile } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('user_id', data.user.id)
          .single();

        if (profile) {
          setProfile(profile);
          await supabase
            .from('user_profiles')
            .update({ last_seen: new Date().toISOString() })
            .eq('user_id', data.user.id);
        }

        toast.success('Welcome back!');
        navigate('/dashboard');
      }
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  }, [navigate, setUser, setProfile, setLoading]);

  const signOut = useCallback(async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      setUser(null);
      setProfile(null);
      navigate('/login');
      toast.success('Successfully signed out');
    } catch (error) {
      handleAuthError(error);
    }
  }, [navigate, setUser, setProfile]);

  const resetPassword = useCallback(async (email: string) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      
      if (error) throw error;
      
      toast.success('Password reset email sent');
    } catch (error) {
      handleAuthError(error);
    }
  }, []);

  return {
    signIn,
    signUp: handleSignUp,
    signOut,
    resetPassword
  };
};