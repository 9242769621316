import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

interface SmtpServer {
  id: string;
  title: string;
  email: string;
  createdAt: string;
  host: string;
  port: number;
  authType: 'none' | 'ssl' | 'tls';
  username?: string;
  password?: string;
  timeout: number;
  sslVerification: boolean;
}

interface SmtpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (server: Partial<SmtpServer>) => void;
  server: SmtpServer | null;
}

const SmtpModal: React.FC<SmtpModalProps> = ({ isOpen, onClose, onSave, server }) => {
  const [formData, setFormData] = useState<Partial<SmtpServer>>({
    title: '',
    email: '',
    host: '',
    port: 587,
    authType: 'tls',
    username: '',
    password: '',
    timeout: 30,
    sslVerification: true
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (server) {
      setFormData(server);
    } else {
      setFormData({
        title: '',
        email: '',
        host: '',
        port: 587,
        authType: 'tls',
        username: '',
        password: '',
        timeout: 30,
        sslVerification: true
      });
    }
    setErrors({});
  }, [server, isOpen]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.title?.trim()) {
      newErrors.title = 'Title is required';
    }

    if (!formData.email?.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!formData.host?.trim()) {
      newErrors.host = 'Host is required';
    }

    if (!formData.port || formData.port < 1 || formData.port > 65535) {
      newErrors.port = 'Port must be between 1 and 65535';
    }

    if (formData.timeout && (formData.timeout < 1 || formData.timeout > 300)) {
      newErrors.timeout = 'Timeout must be between 1 and 300 seconds';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSave(formData);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={onClose}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {server ? 'Edit SMTP Server' : 'Add SMTP Server'}
              </h3>

              <form onSubmit={handleSubmit} className="mt-6 space-y-4">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Title/Description
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      errors.title ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                  {errors.title && <p className="mt-1 text-sm text-red-600">{errors.title}</p>}
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      errors.email ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                  {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
                </div>

                <div>
                  <label htmlFor="host" className="block text-sm font-medium text-gray-700">
                    Host
                  </label>
                  <input
                    type="text"
                    id="host"
                    value={formData.host}
                    onChange={(e) => setFormData({ ...formData, host: e.target.value })}
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      errors.host ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                  {errors.host && <p className="mt-1 text-sm text-red-600">{errors.host}</p>}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="port" className="block text-sm font-medium text-gray-700">
                      Port
                    </label>
                    <input
                      type="number"
                      id="port"
                      value={formData.port}
                      onChange={(e) => setFormData({ ...formData, port: parseInt(e.target.value) })}
                      className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                        errors.port ? 'border-red-300' : 'border-gray-300'
                      }`}
                    />
                    {errors.port && <p className="mt-1 text-sm text-red-600">{errors.port}</p>}
                  </div>

                  <div>
                    <label htmlFor="authType" className="block text-sm font-medium text-gray-700">
                      Authentication Type
                    </label>
                    <select
                      id="authType"
                      value={formData.authType}
                      onChange={(e) => setFormData({ ...formData, authType: e.target.value as 'none' | 'ssl' | 'tls' })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                    >
                      <option value="none">None</option>
                      <option value="ssl">SSL</option>
                      <option value="tls">TLS</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                    Username (if different from email)
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={formData.username}
                    onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                {!server && (
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                    />
                  </div>
                )}

                <div>
                  <label htmlFor="timeout" className="block text-sm font-medium text-gray-700">
                    Connection Timeout (seconds)
                  </label>
                  <input
                    type="number"
                    id="timeout"
                    value={formData.timeout}
                    onChange={(e) => setFormData({ ...formData, timeout: parseInt(e.target.value) })}
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      errors.timeout ? 'border-red-300' : 'border-gray-300'
                    }`}
                  />
                  {errors.timeout && <p className="mt-1 text-sm text-red-600">{errors.timeout}</p>}
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="sslVerification"
                    checked={formData.sslVerification}
                    onChange={(e) => setFormData({ ...formData, sslVerification: e.target.checked })}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="sslVerification" className="ml-2 block text-sm text-gray-700">
                    Enable SSL Verification
                  </label>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus: center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {server ? 'Update' : 'Add'} SMTP Server
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmtpModal;