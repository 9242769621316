import React from 'react';
import { ArrowLeft, Building2, MapPin, Bookmark, Star, Award, Factory } from 'lucide-react';
import StatisticsOverview from './SupplierStatistics';
import ProductCapabilities from './ProductCapabilities';
import ExportHistory from './ExportHistory';
import MarketPresence from './MarketPresence';
import QualitySection from './QualitySection';
import ContactSection from './ContactSection';

interface SupplierProfileProps {
  supplier: {
    id: number;
    name: string;
    country: string;
    city: string;
    exportValue: number;
    frequency: number;
    trend: string;
    rating: number;
    verified: boolean;
    yearEstablished?: number;
    certifications?: string[];
    productionCapacity?: string;
  };
  onBack: () => void;
}

const SupplierProfile: React.FC<SupplierProfileProps> = ({ supplier, onBack }) => {
  const stats = {
    totalExports: `${(supplier.exportValue / 1000000).toFixed(1)}M USD`,
    annualShipments: supplier.frequency.toString(),
    marketShare: '12%',
    destinations: '28',
    productLines: '15'
  };

  const handleAddToLeads = () => {
    // Implement add to leads functionality
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={onBack}
        className="mb-6 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Search Results
      </button>

      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-md mb-8">
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="h-16 w-16 rounded-full bg-indigo-100 flex items-center justify-center">
                <Building2 className="h-8 w-8 text-indigo-600" />
              </div>
              <div className="ml-4">
                <div className="flex items-center">
                  <h1 className="text-2xl font-bold text-gray-900">{supplier.name}</h1>
                  {supplier.verified && (
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      <Award className="h-4 w-4 mr-1" />
                      Verified Supplier
                    </span>
                  )}
                </div>
                <div className="flex items-center mt-2">
                  <MapPin className="h-4 w-4 text-gray-400 mr-1" />
                  <span className="text-gray-500">{supplier.city}, {supplier.country}</span>
                  <span className="mx-2 text-gray-300">|</span>
                  <Factory className="h-4 w-4 text-gray-400 mr-1" />
                  <span className="text-gray-500">Est. {supplier.yearEstablished || 'N/A'}</span>
                  <span className="mx-2 text-gray-300">|</span>
                  <div className="flex items-center">
                    {Array.from({ length: supplier.rating }).map((_, i) => (
                      <Star key={i} className="h-4 w-4 text-yellow-400" fill="currentColor" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleAddToLeads}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                <Bookmark className="h-4 w-4 mr-2" />
                Add to My Leads
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Statistics Overview */}
      <div className="mb-8">
        <StatisticsOverview stats={stats} />
      </div>

      {/* Product Capabilities */}
      <div className="mb-8">
        <ProductCapabilities supplier={supplier} />
      </div>

      {/* Export History */}
      <div className="mb-8">
        <ExportHistory supplier={supplier} />
      </div>

      {/* Market Presence */}
      <div className="mb-8">
        <MarketPresence supplier={supplier} />
      </div>

      {/* Quality & Certifications */}
      <div className="mb-8">
        <QualitySection supplier={supplier} />
      </div>

      {/* Contact Information */}
      <div className="mb-8">
        <ContactSection supplier={supplier} />
      </div>
    </div>
  );
};

export default SupplierProfile;