import React, { useState } from 'react';
import { AlertCircle, Plus, X } from 'lucide-react';

interface CampaignSetupProps {
  data: {
    name: string;
    description?: string;
    goals?: string[];
  };
  onChange: (data: any) => void;
  errors: Record<string, string>;
}

const PRESET_GOALS = [
  'Introduction',
  'Build Relationship',
  'Product Showcase',
  'Send Offers',
  'Schedule Meeting',
  'Follow Up',
  'Sales Conversion',
  'Feedback Request',
  'Partnership Proposal',
  'Market Research'
];

const CampaignSetup: React.FC<CampaignSetupProps> = ({ data, onChange, errors }) => {
  const [goalInput, setGoalInput] = useState('');
  const [showPresets, setShowPresets] = useState(false);

  const handleGoalKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (goalInput.trim()) {
        addGoal(goalInput.trim());
      }
    }
  };

  const addGoal = (goal: string) => {
    const goals = new Set(data.goals || []);
    goals.add(goal);
    onChange({ ...data, goals: Array.from(goals) });
    setGoalInput('');
    setShowPresets(false);
  };

  const removeGoal = (goalToRemove: string) => {
    const goals = (data.goals || []).filter(goal => goal !== goalToRemove);
    onChange({ ...data, goals });
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Campaign Setup</h3>
        <p className="mt-1 text-sm text-gray-500">
          Set up your campaign details and basic configuration.
        </p>
      </div>

      <div className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Campaign Name *
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="name"
              value={data.name}
              onChange={(e) => onChange({ ...data, name: e.target.value })}
              className={`block w-full rounded-md shadow-sm sm:text-sm ${
                errors.name
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.name && (
              <p className="mt-2 text-sm text-red-600 flex items-center">
                <AlertCircle className="h-4 w-4 mr-1" />
                {errors.name}
              </p>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <div className="mt-1">
            <textarea
              id="description"
              rows={4}
              value={data.description}
              onChange={(e) => onChange({ ...data, description: e.target.value })}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Add a description for your campaign..."
            />
          </div>
        </div>

        <div>
          <label htmlFor="goals" className="block text-sm font-medium text-gray-700">
            Campaign Goals
          </label>
          <div className="mt-1">
            <div className="relative">
              <input
                type="text"
                id="goals"
                value={goalInput}
                onChange={(e) => setGoalInput(e.target.value)}
                onKeyDown={handleGoalKeyDown}
                onFocus={() => setShowPresets(true)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Type a goal and press Enter"
              />
              <button
                type="button"
                onClick={() => setShowPresets(!showPresets)}
                className="absolute inset-y-0 right-0 px-3 flex items-center"
              >
                <Plus className="h-4 w-4 text-gray-400" />
              </button>
            </div>

            {showPresets && (
              <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {PRESET_GOALS.filter(goal => 
                  !data.goals?.includes(goal) &&
                  goal.toLowerCase().includes(goalInput.toLowerCase())
                ).map((goal) => (
                  <div
                    key={goal}
                    className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-50"
                    onClick={() => addGoal(goal)}
                  >
                    <span className="block truncate">{goal}</span>
                  </div>
                ))}
              </div>
            )}

            <div className="mt-2 flex flex-wrap gap-2">
              {(data.goals || []).map((goal) => (
                <span
                  key={goal}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                >
                  {goal}
                  <button
                    type="button"
                    onClick={() => removeGoal(goal)}
                    className="ml-1 inline-flex items-center p-0.5 rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Add goals to help AI generate a more targeted email sequence
          </p>
        </div>
      </div>
    </div>
  );
};

export default CampaignSetup;