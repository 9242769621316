import React from 'react';
import { Award, Shield, CheckCircle } from 'lucide-react';

interface QualitySectionProps {
  supplier: {
    certifications?: string[];
  };
}

const QualitySection: React.FC<QualitySectionProps> = ({ supplier }) => {
  const certifications = supplier.certifications || [
    'ISO 9001:2015',
    'ISO 14001:2015',
    'CE Certification',
    'RoHS Compliant'
  ];

  const qualityMetrics = [
    { label: 'Quality Control Staff', value: '45+' },
    { label: 'Quality Control Process', value: '6-step' },
    { label: 'Defect Rate', value: '<0.5%' },
    { label: 'On-time Delivery', value: '98%' }
  ];

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">Quality & Certifications</h2>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Certifications */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-4">Certifications</h3>
            <div className="grid grid-cols-2 gap-4">
              {certifications.map((cert, index) => (
                <div
                  key={index}
                  className="flex items-center p-3 bg-gray-50 rounded-lg"
                >
                  <Award className="h-5 w-5 text-indigo-600 mr-2" />
                  <span className="text-sm text-gray-700">{cert}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Quality Metrics */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-4">Quality Metrics</h3>
            <div className="grid grid-cols-2 gap-4">
              {qualityMetrics.map((metric, index) => (
                <div
                  key={index}
                  className="flex items-center p-3 bg-gray-50 rounded-lg"
                >
                  <CheckCircle className="h-5 w-5 text-green-600 mr-2" />
                  <div>
                    <p className="text-xs text-gray-500">{metric.label}</p>
                    <p className="text-sm font-medium text-gray-900">{metric.value}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualitySection;