import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../lib/supabase/providers/AuthProvider';
import Layout from '../components/Layout';

// Auth Pages
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import EmailVerification from '../pages/EmailVerification';

// Main Pages
import Dashboard from '../pages/Dashboard';
import BuyerSearch from '../pages/BuyerSearch';
import BuyerTrends from '../pages/BuyerTrends';
import BuyerOutreach from '../pages/BuyerOutreach';
import Settings from '../pages/Settings';
import SearchHistory from '../pages/SearchHistory';
import HSCodeList from '../pages/HSCodeList';
import MyLeads from '../pages/MyLeads';
import LeadDetail from '../pages/LeadDetail';
import SupplierSearch from '../pages/SupplierSearch';
import CompetitorTracker from '../pages/CompetitorTracker';
import CampaignCreate from '../pages/CampaignCreate';
import CampaignView from '../pages/CampaignView';
import CampaignEdit from '../pages/CampaignEdit';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Layout>{children}</Layout>;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<EmailVerification />} />
      
      {/* Default Redirect */}
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      
      {/* Protected Routes */}
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      
      <Route path="/buyer-search" element={
        <ProtectedRoute>
          <BuyerSearch />
        </ProtectedRoute>
      } />
      
      <Route path="/supplier-search" element={
        <ProtectedRoute>
          <SupplierSearch />
        </ProtectedRoute>
      } />

      <Route path="/competitor-tracker" element={
        <ProtectedRoute>
          <CompetitorTracker />
        </ProtectedRoute>
      } />
      
      <Route path="/buyer-trends" element={
        <ProtectedRoute>
          <BuyerTrends />
        </ProtectedRoute>
      } />
      
      <Route path="/buyer-outreach" element={
        <ProtectedRoute>
          <BuyerOutreach />
        </ProtectedRoute>
      } />

      <Route path="/campaign/create" element={
        <ProtectedRoute>
          <CampaignCreate />
        </ProtectedRoute>
      } />

      <Route path="/campaign/edit/:id" element={
        <ProtectedRoute>
          <CampaignEdit />
        </ProtectedRoute>
      } />

      <Route path="/campaign/:id" element={
        <ProtectedRoute>
          <CampaignView />
        </ProtectedRoute>
      } />
      
      <Route path="/settings" element={
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      } />
      
      <Route path="/search-history" element={
        <ProtectedRoute>
          <SearchHistory />
        </ProtectedRoute>
      } />

      <Route path="/hs-codes" element={
        <ProtectedRoute>
          <HSCodeList />
        </ProtectedRoute>
      } />
      
      <Route path="/my-leads" element={
        <ProtectedRoute>
          <MyLeads />
        </ProtectedRoute>
      } />

      <Route path="/my-leads/:id" element={
        <ProtectedRoute>
          <LeadDetail />
        </ProtectedRoute>
      } />
    </Routes>
  );
};

export default AppRoutes;