import React, { useState } from 'react';
import PasswordChange from '../components/settings/PasswordChange';
import AccessManagement from '../components/settings/AccessManagement';
import AccessRoles from '../components/settings/AccessRoles';
import { 
  User, 
  CreditCard,
  Lock,
  Building,
  Phone,
  Globe,
  Users as TeamIcon,
  Shield,
  Server
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import SmtpList from '../components/settings/SmtpList';
import PaymentHistory from '../components/settings/PaymentHistory';
import CompanyProfile from '../components/settings/CompanyProfile';

type TabType = 'account' | 'payment' | 'access' | 'roles' | 'smtp' | 'company';

const Settings = () => {
  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error';
  } | null>(null);

  const [activeTab, setActiveTab] = useState<TabType>('account');
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth();

  const tabs = [
    { id: 'account', label: 'Account', icon: User },
    { id: 'payment', label: 'Payment', icon: CreditCard },
    { id: 'access', label: 'Access', icon: TeamIcon },
    { id: 'roles', label: 'Access Roles', icon: Shield },
    { id: 'smtp', label: 'SMTP List', icon: Server },
    { id: 'company', label: 'Company', icon: Building }
  ];

  const handlePasswordChange = async (oldPassword: string, newPassword: string) => {
    try {
      // Implement actual password change logic here
      await new Promise(resolve => setTimeout(resolve, 1000));
      setNotification({
        message: 'Password updated successfully',
        type: 'success'
      });
    } catch (error) {
      throw new Error('Failed to update password');
    }
  };

  const handleProfileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle profile update logic
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
        <p className="mt-1 text-sm text-gray-500">
          Manage your account settings and preferences
        </p>
        
        {notification && (
          <div className="mt-4">
            <div className={`rounded-md p-4 ${
              notification.type === 'success' ? 'bg-green-50' : 'bg-red-50'
            }`}>
              <div className="flex">
                <div className="ml-3">
                  <p className={`text-sm ${
                    notification.type === 'success' ? 'text-green-800' : 'text-red-800'
                  }`}>
                    {notification.message}
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      onClick={() => setNotification(null)}
                      className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                        notification.type === 'success'
                          ? 'text-green-500 hover:bg-green-100 focus:ring-green-600'
                          : 'text-red-500 hover:bg-red-100 focus:ring-red-600'
                      }`}
                    >
                      <span className="sr-only">Dismiss</span>
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bg-white rounded-xl shadow-md">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-8 px-6" aria-label="Tabs">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id as TabType)}
                  className={`
                    py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm
                    ${activeTab === tab.id
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  <Icon className="h-5 w-5 mr-2" />
                  {tab.label}
                </button>
              );
            })}
          </nav>
        </div>

        <div className="p-6">
          {activeTab === 'account' && (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Profile Information</h3>
                <p className="mt-1 text-sm text-gray-500">Update your personal information.</p>
              </div>

              <div className="space-y-6">
                {/* Profile Form */}
                <form onSubmit={handleProfileSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="name"
                          defaultValue={user?.name}
                          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Building className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="company"
                          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Phone className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="tel"
                          id="phone"
                          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Globe className="h-5 w-5 text-gray-400" />
                        </div>
                        <select
                          id="country"
                          defaultValue={user?.country}
                          className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="US">United States</option>
                          <option value="UK">United Kingdom</option>
                          <option value="CA">Canada</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>

                {/* Password Change Section */}
                <div className="border-t border-gray-200 pt-6">
                  <PasswordChange
                    onPasswordChange={handlePasswordChange}
                    onNotification={(message, type) => setNotification({
                      message, type
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          {activeTab === 'payment' && (
            <div className="space-y-6">
              <PaymentHistory />
            </div>
          )}

          {activeTab === 'access' && (
            <div className="space-y-6">
              <AccessManagement />
            </div>
          )}

          {activeTab === 'roles' && (
            <div className="space-y-6">
              <AccessRoles />
            </div>
          )}

          {activeTab === 'smtp' && <SmtpList />}

          {activeTab === 'company' && <CompanyProfile />}
        </div>
      </div>
    </div>
  );
};

export default Settings;