import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';

interface SmtpAccount {
  id: string;
  title: string;
  email: string;
}

interface SmtpConfigProps {
  config: {
    accounts: Array<{
      id: string;
      percentage: number;
    }>;
  };
  onChange: (config: any) => void;
  errors: Record<string, string>;
}

const SmtpConfig: React.FC<SmtpConfigProps> = ({ config, onChange, errors }) => {
  // Mock data - replace with actual SMTP accounts from your system
  const availableAccounts: SmtpAccount[] = [
    { id: '1', title: 'Primary SMTP', email: 'smtp1@company.com' },
    { id: '2', title: 'Secondary SMTP', email: 'smtp2@company.com' },
    { id: '3', title: 'Marketing SMTP', email: 'marketing@company.com' },
  ];

  const handleAccountSelection = (accountId: string) => {
    const isSelected = config.accounts.some(acc => acc.id === accountId);
    
    if (isSelected) {
      onChange({
        accounts: config.accounts.filter(acc => acc.id !== accountId)
      });
    } else {
      const remainingPercentage = 100 - config.accounts.reduce((sum, acc) => sum + acc.percentage, 0);
      onChange({
        accounts: [
          ...config.accounts,
          { id: accountId, percentage: remainingPercentage }
        ]
      });
    }
  };

  const handlePercentageChange = (accountId: string, percentage: number) => {
    const updatedAccounts = config.accounts.map(acc => {
      if (acc.id === accountId) {
        return { ...acc, percentage };
      }
      return acc;
    });
    onChange({ accounts: updatedAccounts });
  };

  const totalPercentage = config.accounts.reduce((sum, acc) => sum + acc.percentage, 0);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">SMTP Configuration</h3>
        <p className="mt-1 text-sm text-gray-500">
          Select and configure SMTP servers for your campaign.
        </p>

        {/* Distribution Summary - Moved here */}
        <div className="mt-4 bg-gray-50 rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <div>
              <p className="text-sm font-medium text-gray-700">Total Distribution</p>
              <p className="text-sm text-gray-500">
                {config.accounts.length} server{config.accounts.length !== 1 ? 's' : ''} selected
              </p>
            </div>
            <div className="text-2xl font-semibold text-gray-900">
              {totalPercentage}%
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className={`h-2.5 rounded-full ${
                totalPercentage === 100 ? 'bg-green-600' : 'bg-yellow-400'
              }`}
              style={{ width: `${totalPercentage}%` }}
            ></div>
          </div>
        </div>
      </div>

      {/* SMTP Account Selection */}
      <div className="space-y-4">
        {availableAccounts.map((account) => {
          const selectedAccount = config.accounts.find(acc => acc.id === account.id);
          const isSelected = !!selectedAccount;

          return (
            <div key={account.id} className="border rounded-lg p-4 bg-white">
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleAccountSelection(account.id)}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3">
                    <label className="text-sm font-medium text-gray-700">
                      {account.title}
                    </label>
                    <p className="text-sm text-gray-500">{account.email}</p>
                  </div>
                </div>
                {isSelected && (
                  <div className="flex items-center space-x-4">
                    <label className="text-sm text-gray-700">Distribution %</label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={selectedAccount.percentage}
                      onChange={(e) => handlePercentageChange(account.id, Number(e.target.value))}
                      className="block w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {errors.smtp && (
        <p className="mt-2 text-sm text-red-600 flex items-center">
          <AlertCircle className="h-4 w-4 mr-1" />
          {errors.smtp}
        </p>
      )}
      {errors.smtpPercentage && (
        <p className="mt-2 text-sm text-red-600 flex items-center">
          <AlertCircle className="h-4 w-4 mr-1" />
          {errors.smtpPercentage}
        </p>
      )}
    </div>
  );
};

export default SmtpConfig;