import React, { useState } from 'react';
import { Users, Plus, Trash2, Mail, AlertCircle, CheckCircle } from 'lucide-react';
import AddMemberModal from './AddMemberModal';

interface TeamMember {
  id: string;
  name: string;
  email: string;
  role: string;
  status: 'active' | 'pending';
  lastActive?: string;
}

const AccessManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [members, setMembers] = useState<TeamMember[]>([
    {
      id: '1',
      name: 'John Doe',
      email: 'john@company.com',
      role: 'Admin',
      status: 'active',
      lastActive: '2024-03-15'
    },
    {
      id: '2',
      name: 'Jane Smith',
      email: 'jane@company.com',
      role: 'Member',
      status: 'pending'
    }
  ]);

  const totalSlots = 5;
  const usedSlots = members.length;
  const remainingSlots = totalSlots - usedSlots;

  const handleAddMember = (newMember: Omit<TeamMember, 'id' | 'status'>) => {
    if (usedSlots >= totalSlots) {
      return;
    }

    setMembers([
      ...members,
      {
        ...newMember,
        id: Date.now().toString(),
        status: 'pending'
      }
    ]);
    setIsModalOpen(false);
  };

  const handleRemoveMember = (id: string) => {
    setMembers(members.filter(member => member.id !== id));
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Access Management</h3>
        <p className="mt-1 text-sm text-gray-500">
          Manage team members and their access levels
        </p>
      </div>

      {/* Status Box */}
      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-sm font-medium text-gray-900">Team Access Status</h4>
            <p className="mt-1 text-sm text-gray-500">
              {remainingSlots} of {totalSlots} member slots remaining
            </p>
          </div>
          <div className="flex items-center">
            <div className="w-32 bg-gray-200 rounded-full h-2">
              <div
                className="bg-indigo-600 h-2 rounded-full"
                style={{ width: `${(usedSlots / totalSlots) * 100}%` }}
              />
            </div>
            <span className="ml-3 text-sm text-gray-500">
              {Math.round((usedSlots / totalSlots) * 100)}% used
            </span>
          </div>
        </div>
      </div>

      {/* Team Members List */}
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-4 border-b border-gray-200 sm:px-6">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-900">Team Members</h3>
            <button
              onClick={() => setIsModalOpen(true)}
              disabled={usedSlots >= totalSlots}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Member
            </button>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {members.map((member) => (
            <li key={member.id} className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                      <Users className="h-5 w-5 text-indigo-600" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="flex items-center">
                      <h4 className="text-sm font-medium text-gray-900">{member.name}</h4>
                      <span className={`ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        member.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {member.status === 'active' ? (
                          <CheckCircle className="h-3 w-3 mr-1" />
                        ) : (
                          <AlertCircle className="h-3 w-3 mr-1" />
                        )}
                        {member.status === 'active' ? 'Active' : 'Pending'}
                      </span>
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <Mail className="h-4 w-4 mr-1" />
                      {member.email}
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {member.role}
                  </span>
                  {member.lastActive && (
                    <span className="text-sm text-gray-500">
                      Last active: {member.lastActive}
                    </span>
                  )}
                  {member.role !== 'Admin' && (
                    <button
                      onClick={() => handleRemoveMember(member.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <AddMemberModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={handleAddMember}
      />
    </div>
  );
};

export default AccessManagement;