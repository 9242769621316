import React from 'react';
import { Mail, Users, Server, Clock } from 'lucide-react';

interface CampaignPreviewProps {
  campaign: {
    name: string;
    description?: string;
    selectedLeads: string[];
    smtpConfig: {
      accounts: Array<{
        id: string;
        percentage: number;
      }>;
    };
    sequence: Array<{
      id: string;
      subject: string;
      content: string;
      delay: number;
    }>;
  };
}

const CampaignPreview: React.FC<CampaignPreviewProps> = ({ campaign }) => {
  const totalDuration = campaign.sequence.reduce((sum, step) => sum + step.delay, 0);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Campaign Preview</h3>
        <p className="mt-1 text-sm text-gray-500">
          Review your campaign configuration before launching.
        </p>
      </div>

      {/* Campaign Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-lg p-6 border border-gray-200">
          <div className="flex items-center">
            <Mail className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Email Steps</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {campaign.sequence.length}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg p-6 border border-gray-200">
          <div className="flex items-center">
            <Users className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Recipients</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {campaign.selectedLeads.length}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg p-6 border border-gray-200">
          <div className="flex items-center">
            <Server className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">SMTP Servers</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {campaign.smtpConfig.accounts.length}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg p-6 border border-gray-200">
          <div className="flex items-center">
            <Clock className="h-8 w-8 text-indigo-600" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Duration</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {totalDuration} days
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Sequence Timeline */}
      <div className="bg-white rounded-lg border border-gray-200">
        <div className="px-6 py-4 border-b border-gray-200">
          <h4 className="text-sm font-medium text-gray-900">Sequence Timeline</h4>
        </div>
        <div className="p-6">
          <div className="relative">
            {campaign.sequence.map((step, index) => (
              <div key={step.id} className="flex items-start mb-8 last:mb-0">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 text-indigo-600 text-sm font-medium">
                  {index + 1}
                </div>
                <div className="ml-4 flex-1">
                  <div className="bg-gray-50 rounded-lg p-4">
                    <p className="text-sm font-medium text-gray-900">{step.subject}</p>
                    <p className="mt-1 text-sm text-gray-500">
                      {index === 0 ? 'Sent immediately' : `Sent after ${step.delay} days`}
                    </p>
                    <p className="mt-2 text-sm text-gray-700">{step.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* SMTP Distribution */}
      <div className="bg-white rounded-lg border border-gray-200">
        <div className="px-6 py-4 border-b border-gray-200">
          <h4 className="text-sm font-medium text-gray-900">SMTP Distribution</h4>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            {campaign.smtpConfig.accounts.map((account) => (
              <div key={account.id} className="flex items-center">
                <div className="w-32">
                  <p className="text-sm font-medium text-gray-900">SMTP {account.id}</p>
                </div>
                <div className="flex-1">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-indigo-600 h-2.5 rounded-full"
                      style={{ width: `${account.percentage}%` }}
                    ></div>
                  </div>
                </div>
                <div className="w-16 text-right">
                  <p className="text-sm font-medium text-gray-900">{account.percentage}%</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPreview;