import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Users, ArrowRight, Calendar, FolderOpen, Target, Database, PieChart, Tag, Trash2 } from 'lucide-react';
import StatCard from '../components/StatCard';

type LeadSource = 'competitor' | 'buyer' | 'supplier';

interface LeadGroup {
  id: number;
  title: string;
  companies: number;
  dateSaved: string;
  source: LeadSource;
  searchCriteria: {
    hsCode?: string;
    country?: string;
  };
}

const MyLeads = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [leadGroups, setLeadGroups] = useState<LeadGroup[]>([
    {
      id: 1,
      title: 'US Electronics Importers',
      companies: 12,
      dateSaved: '2024-03-15',
      source: 'buyer',
      searchCriteria: {
        hsCode: '8544.42',
        country: 'US',
      },
    },
    {
      id: 2,
      title: 'German Manufacturing',
      companies: 8,
      dateSaved: '2024-03-14',
      source: 'supplier',
      searchCriteria: {
        hsCode: '8501.10',
        country: 'DE',
      },
    },
    {
      id: 3,
      title: 'Asian Competitors',
      companies: 15,
      dateSaved: '2024-03-13',
      source: 'competitor',
      searchCriteria: {
        country: 'Asia',
      },
    }
  ]);

  const totalPages = Math.ceil(leadGroups.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLeadGroups = leadGroups.slice(startIndex, endIndex);

  const getSourceBadgeColor = (source: LeadSource) => {
    switch (source) {
      case 'competitor':
        return 'bg-red-100 text-red-800';
      case 'buyer':
        return 'bg-green-100 text-green-800';
      case 'supplier':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getSourceLabel = (source: LeadSource) => {
    switch (source) {
      case 'competitor':
        return 'Competitor';
      case 'buyer':
        return 'Buyer';
      case 'supplier':
        return 'Supplier';
      default:
        return 'Unknown';
    }
  };

  const handleDelete = (groupId: number) => {
    if (window.confirm('Are you sure you want to delete this lead group?')) {
      setLeadGroups(prevGroups => {
        const newGroups = prevGroups.filter(group => group.id !== groupId);
        // Adjust current page if necessary
        const newTotalPages = Math.ceil(newGroups.length / itemsPerPage);
        if (currentPage > newTotalPages) {
          setCurrentPage(Math.max(1, newTotalPages));
        }
        return newGroups;
      });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">My Leads</h1>
        <p className="mt-1 text-sm text-gray-500">
          Manage your saved lead groups and track potential partners
        </p>
      </div>

      {/* Statistics Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Lead Groups"
          value="8"
          icon={FolderOpen}
          trend={{ value: "2", isPositive: true }}
        />
        <StatCard
          title="Total Leads"
          value="156"
          icon={Target}
          trend={{ value: "12%", isPositive: true }}
        />
        <StatCard
          title="Account Limit"
          value="500"
          icon={Database}
          trend={{ value: "69%", isPositive: true }}
        />
        <StatCard
          title="Consumed"
          value="31%"
          icon={PieChart}
          trend={{ value: "5%", isPositive: false }}
        />
      </div>

      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="divide-y divide-gray-200">
          {currentLeadGroups.map((group) => (
            <div key={group.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                    <Users className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <h3 className="text-lg font-medium text-gray-900">{group.title}</h3>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getSourceBadgeColor(group.source)}`}>
                        <Tag className="h-3 w-3 mr-1" />
                        {getSourceLabel(group.source)}
                      </span>
                    </div>
                    <div className="mt-1 flex items-center space-x-4 text-sm text-gray-500">
                      <span>{group.companies} companies</span>
                      <span>•</span>
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 mr-1" />
                        Saved on {group.dateSaved}
                      </div>
                    </div>
                    {group.searchCriteria.hsCode && (
                      <div className="mt-2 flex items-center space-x-2">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          HS: {group.searchCriteria.hsCode}
                        </span>
                        {group.searchCriteria.country && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {group.searchCriteria.country}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => handleDelete(group.id)}
                    className="text-gray-400 hover:text-red-600 transition-colors duration-200"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                  <Link
                    to={`/my-leads/${group.id}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    View All
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        {leadGroups.length > 0 && (
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(endIndex, leadGroups.length)}</span> of{' '}
                  <span className="font-medium">{leadGroups.length}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1)
                    .filter(page => {
                      const distance = Math.abs(page - currentPage);
                      return distance === 0 || distance === 1 || page === 1 || page === totalPages;
                    })
                    .map((page, index, array) => {
                      if (index > 0 && array[index - 1] !== page - 1) {
                        return [
                          <span key={`ellipsis-${page}`} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                            ...
                          </span>,
                          <button
                            key={page}
                            onClick={() => setCurrentPage(page)}
                            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                              currentPage === page
                                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                : 'bg-white text-gray-700 hover:bg-gray-50'
                            }`}
                          >
                            {page}
                          </button>
                        ];
                      }
                      return (
                        <button
                          key={page}
                          onClick={() => setCurrentPage(page)}
                          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                            currentPage === page
                              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                              : 'bg-white text-gray-700 hover:bg-gray-50'
                          }`}
                        >
                          {page}
                        </button>
                      );
                    })}
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    Next
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLeads;