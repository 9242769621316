import React from 'react';
import { Download, Trash2 } from 'lucide-react';

interface BulkActionsProps {
  onExport: () => void;
  onDelete: () => void;
}

const BulkActions: React.FC<BulkActionsProps> = ({ onExport, onDelete }) => {
  return (
    <div className="mb-4 flex items-center justify-between bg-indigo-50 px-4 py-2 rounded-lg">
      <span className="text-sm text-indigo-700">All items selected</span>
      <div className="flex space-x-4">
        <button
          onClick={onExport}
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="h-4 w-4 mr-2" />
          Export as CSV
        </button>
        <button
          onClick={onDelete}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
        >
          <Trash2 className="h-4 w-4 mr-2" />
          Delete All
        </button>
      </div>
    </div>
  );
};

export default BulkActions;