import React, { useState } from 'react';
import { Wand2, AlertCircle, Loader2 } from 'lucide-react';
import { generateEmailSequence } from '../../utils/claude';
import toast from 'react-hot-toast';
import type { SequenceStep } from '../../utils/api/types';

interface AISequenceGeneratorProps {
  campaignName: string;
  description?: string;
  targetAudience: {
    industry?: string;
    region?: string;
    companySize?: string;
  };
  goals?: string[];
  onSequenceGenerated: (sequence: EmailStep[]) => void;
}

interface EmailStep {
  id: string;
  subject: string;
  content: string;
  delay: number;
  step_name?: string;
  campaign_goal?: string;
  target_audience?: string;
  personalization_variables?: string[];
}

const AISequenceGenerator: React.FC<AISequenceGeneratorProps> = ({
  campaignName,
  description,
  targetAudience,
  goals,
  onSequenceGenerated
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateSequence = async () => {
    if (!campaignName?.trim()) {
      toast.error('Please provide a campaign name before generating a sequence');
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const sequence = await generateEmailSequence({
        campaignName,
        description,
        goals,
        industry: targetAudience.industry,
        region: targetAudience.region
      });

      const emailSteps: EmailStep[] = sequence.map(step => ({
        id: `step-${step.step_number}`,
        subject: step.subject,
        content: step.content,
        delay: step.delay,
        step_name: step.step_name,
        campaign_goal: step.campaign_goal,
        target_audience: step.target_audience,
        personalization_variables: step.personalization_variables
      }));

      onSequenceGenerated(emailSteps);
      toast.success('Email sequence generated successfully!');
    } catch (err) {
      const errorMessage = err instanceof Error 
        ? err.message 
        : 'Failed to generate sequence. Please try again.';
      
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-sm font-medium text-gray-900">AI Sequence Generator</h3>
          <p className="mt-1 text-sm text-gray-500">
            Generate a complete email sequence optimized for your campaign goals
          </p>
        </div>
        <button
          onClick={generateSequence}
          disabled={isGenerating || !campaignName}
          className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
            isGenerating || !campaignName
              ? 'bg-indigo-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700'
          }`}
        >
          {isGenerating ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Generating...
            </>
          ) : (
            <>
              <Wand2 className="h-4 w-4 mr-2" />
              Generate Sequence
            </>
          )}
        </button>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AISequenceGenerator;