import React, { useState } from 'react';
import { Download, Filter, Calendar, CheckCircle, AlertCircle, Clock, Search, CreditCard } from 'lucide-react';
import { format } from 'date-fns';
import { generateInvoicePDF } from '../../utils/invoiceGenerator';

interface Transaction {
  id: string;
  date: string;
  amount: number;
  description: string;
  status: 'successful' | 'pending' | 'failed';
  invoiceNumber: string;
}

interface FilterState {
  dateRange: string;
  minAmount: string;
  maxAmount: string;
  status: string;
  searchTerm: string;
}

const PaymentHistory = () => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [filters, setFilters] = useState<FilterState>({
    dateRange: 'all',
    minAmount: '',
    maxAmount: '',
    status: 'all',
    searchTerm: ''
  });

  const [savedCards, setSavedCards] = useState([
    {
      id: '1',
      last4: '4242',
      brand: 'Visa',
      expMonth: 12,
      expYear: 2024,
      isDefault: true,
      isExpired: false
    },
    {
      id: '2',
      last4: '1234',
      brand: 'Mastercard',
      expMonth: 3,
      expYear: 2023,
      isDefault: false,
      isExpired: true
    }
  ]);

  const transactions: Transaction[] = [
    {
      id: '1',
      date: '2024-03-15',
      amount: 299.99,
      description: 'Premium Plan Subscription',
      status: 'successful',
      invoiceNumber: 'INV-2024-001'
    },
    {
      id: '2',
      date: '2024-02-15',
      amount: 299.99,
      description: 'Premium Plan Subscription',
      status: 'successful',
      invoiceNumber: 'INV-2024-002'
    },
    {
      id: '3',
      date: '2024-01-15',
      amount: 299.99,
      description: 'Premium Plan Subscription',
      status: 'failed',
      invoiceNumber: 'INV-2024-003'
    }
  ];

  const getStatusIcon = (status: Transaction['status']) => {
    switch (status) {
      case 'successful':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'pending':
        return <Clock className="h-5 w-5 text-yellow-500" />;
      case 'failed':
        return <AlertCircle className="h-5 w-5 text-red-500" />;
    }
  };

  const getStatusClass = (status: Transaction['status']) => {
    switch (status) {
      case 'successful':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
    }
  };

  const handleSetDefaultCard = (cardId: string) => {
    setSavedCards(cards =>
      cards.map(card => ({
        ...card,
        isDefault: card.id === cardId
      }))
    );
  };

  const handleRemoveCard = (cardId: string) => {
    setSavedCards(cards => cards.filter(card => card.id !== cardId));
  };

  const getCardBorderClass = (card: typeof savedCards[0]) => {
    if (card.isExpired) return 'border-red-200';
    if (card.isDefault) return 'border-green-500';
    return 'border-gray-200';
  };

  const handleFilterChange = (key: keyof FilterState, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const filteredTransactions = transactions.filter(transaction => {
    if (filters.status !== 'all' && transaction.status !== filters.status) return false;
    if (filters.minAmount && transaction.amount < parseFloat(filters.minAmount)) return false;
    if (filters.maxAmount && transaction.amount > parseFloat(filters.maxAmount)) return false;
    if (filters.searchTerm && !transaction.description.toLowerCase().includes(filters.searchTerm.toLowerCase())) return false;
    return true;
  });

  const handleDownloadInvoice = (transaction: Transaction) => {
    generateInvoicePDF({
      invoiceNumber: transaction.invoiceNumber,
      date: format(new Date(transaction.date), 'MMMM dd, yyyy'),
      amount: transaction.amount,
      description: transaction.description,
      status: transaction.status
    });
  };

  return (
    <div className="mt-8">
      <div className="border-b border-gray-200 pb-4">
        <h3 className="text-lg font-medium text-gray-900">Payment Methods</h3>
      </div>

      {/* Saved Cards Section */}
      <div className="mt-6 space-y-4">
        {savedCards.map(card => (
          <div
            key={card.id}
            className={`p-4 rounded-lg border-2 ${getCardBorderClass(card)} relative`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <CreditCard className={`h-6 w-6 ${card.isExpired ? 'text-red-400' : 'text-gray-400'}`} />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {card.brand} •••• {card.last4}
                  </p>
                  <p className="text-sm text-gray-500">
                    Expires {card.expMonth}/{card.expYear}
                  </p>
                </div>
                {card.isDefault && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Default
                  </span>
                )}
                {card.isExpired && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Expired
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                {!card.isDefault && !card.isExpired && (
                  <button
                    onClick={() => handleSetDefaultCard(card.id)}
                    className="text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    Set as default
                  </button>
                )}
                <button
                  onClick={() => handleRemoveCard(card.id)}
                  className="text-sm text-red-600 hover:text-red-500"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Payment History Section */}
      <div className="mt-8">
        <div className="border-b border-gray-200 pb-4">
          <h3 className="text-lg font-medium text-gray-900">Payment History</h3>
        </div>

        <div className="mt-4 flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search transactions..."
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={filters.searchTerm}
                onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
              />
            </div>
            <button
              onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Filter className="h-4 w-4 mr-2" />
              More Filters
            </button>
          </div>
        </div>

        {/* Filter Panel */}
        {isFilterPanelOpen && (
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Date Range</label>
                <select
                  value={filters.dateRange}
                  onChange={(e) => handleFilterChange('dateRange', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="all">All Time</option>
                  <option value="30">Last 30 Days</option>
                  <option value="90">Last 90 Days</option>
                  <option value="365">Last Year</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Min Amount</label>
                <input
                  type="number"
                  value={filters.minAmount}
                  onChange={(e) => handleFilterChange('minAmount', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="0.00"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Max Amount</label>
                <input
                  type="number"
                  value={filters.maxAmount}
                  onChange={(e) => handleFilterChange('maxAmount', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="0.00"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Status</label>
                <select
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="all">All Status</option>
                  <option value="successful">Successful</option>
                  <option value="pending">Pending</option>
                  <option value="failed">Failed</option>
                </select>
              </div>
            </div>
          </div>
        )}

        <div className="mt-6">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTransactions.map((transaction) => (
                  <tr key={transaction.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                        {format(new Date(transaction.date), 'MMM dd, yyyy')}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {transaction.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      ${transaction.amount.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusClass(transaction.status)}`}>
                        {getStatusIcon(transaction.status)}
                        <span className="ml-1 capitalize">{transaction.status}</span>
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {transaction.status === 'successful' && (
                        <button
                          onClick={() => handleDownloadInvoice(transaction)}
                          className="text-indigo-600 hover:text-indigo-900 inline-flex items-center"
                        >
                          <Download className="h-4 w-4 mr-1" />
                          Invoice
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;