import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Session, User } from '@supabase/supabase-js';
import { supabase } from '../config';
import { useAuthStore } from '../store/auth';
import { useAuth as useSupabaseAuth } from '../hooks/useAuth';
import { getUserProfile } from '../auth/userProfile';

interface AuthContextType {
  user: User | null;
  session: Session | null;
  isLoading: boolean;
  signIn: ReturnType<typeof useSupabaseAuth>['signIn'];
  signUp: ReturnType<typeof useSupabaseAuth>['signUp'];
  signOut: ReturnType<typeof useSupabaseAuth>['signOut'];
  resetPassword: ReturnType<typeof useSupabaseAuth>['resetPassword'];
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState<Session | null>(null);
  const { user, setUser, setProfile, isLoading, setLoading } = useAuthStore();
  const auth = useSupabaseAuth();

  useEffect(() => {
    // Initialize auth state
    const initAuth = async () => {
      try {
        setLoading(true);
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session?.user) {
          setSession(session);
          setUser(session.user);
          
          const profile = await getUserProfile(session.user.id);
          if (profile) {
            setProfile(profile);
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
      } finally {
        setLoading(false);
      }
    };

    initAuth();

    // Set up auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
      setUser(session?.user ?? null);

      if (session?.user) {
        const profile = await getUserProfile(session.user.id);
        if (profile) {
          setProfile(profile);
        }
      } else {
        setProfile(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [setUser, setProfile, setLoading]);

  // Handle auth redirects
  useEffect(() => {
    if (!isLoading) {
      const publicRoutes = ['/login', '/signup', '/forgot-password', '/reset-password', '/verify-email'];
      const isPublicRoute = publicRoutes.includes(location.pathname);

      if (!user && !isPublicRoute) {
        navigate('/login', { replace: true });
      } else if (user && isPublicRoute) {
        navigate('/dashboard', { replace: true });
      }
    }
  }, [user, isLoading, navigate, location]);

  const value = {
    user,
    session,
    isLoading,
    ...auth
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};