import { supabase } from '../config';
import { handleAuthError } from '../utils/errorHandling';
import type { User } from '@supabase/supabase-js';
import type { Database } from '../types';

type Profile = Database['public']['Tables']['user_profiles']['Row'];

export const getUserProfile = async (userId: string): Promise<Profile | null> => {
  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    throw handleAuthError(error);
  }
};

export const createUserProfile = async (user: User, fullName: string): Promise<void> => {
  try {
    const { error } = await supabase
      .from('user_profiles')
      .insert([{
        user_id: user.id,
        full_name: fullName,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        last_seen: new Date().toISOString()
      }]);

    if (error) throw error;
  } catch (error) {
    throw handleAuthError(error);
  }
};

export const updateLastSeen = async (userId: string): Promise<void> => {
  try {
    const { error } = await supabase
      .from('user_profiles')
      .update({ 
        last_seen: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .eq('user_id', userId);

    if (error) throw error;
  } catch (error) {
    throw handleAuthError(error);
  }
};