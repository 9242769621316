import { create } from 'zustand';
import { supabase } from '../config';
import type { User } from '@supabase/supabase-js';
import type { Database } from '../types';

type Profile = Database['public']['Tables']['user_profiles']['Row'];

interface AuthState {
  user: User | null;
  profile: Profile | null;
  isLoading: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: Profile | null) => void;
  setLoading: (isLoading: boolean) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  profile: null,
  isLoading: true,
  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (isLoading) => set({ isLoading })
}));

// Initialize auth state
supabase.auth.getSession().then(({ data: { session } }) => {
  useAuthStore.setState({ user: session?.user ?? null, isLoading: false });
  
  if (session?.user) {
    supabase
      .from('user_profiles')
      .select('*')
      .eq('user_id', session.user.id)
      .single()
      .then(({ data }) => {
        if (data) {
          useAuthStore.setState({ profile: data });
        }
      });
  }
});

// Set up auth state change listener
supabase.auth.onAuthStateChange(async (event, session) => {
  useAuthStore.setState({ user: session?.user ?? null });

  if (event === 'SIGNED_IN' && session?.user) {
    const { data } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('user_id', session.user.id)
      .single();

    if (data) {
      useAuthStore.setState({ profile: data });
    }
  } else if (event === 'SIGNED_OUT') {
    useAuthStore.setState({ profile: null });
  }
});