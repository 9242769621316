import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, 
  TrendingUp, 
  Users, 
  Ship, 
  Bot, 
  Globe, 
  Package,
  Mail,
  Target,
  Crosshair,
  Clock,
  Database,
  Settings,
  ChevronRight
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import StatCard from '../components/StatCard';

interface ToolCardProps {
  title: string;
  description: string;
  icon: React.ElementType;
  to: string;
  metrics?: {
    value: string;
    label: string;
  };
}

const ToolCard: React.FC<ToolCardProps> = ({ title, description, icon: Icon, to, metrics }) => (
  <Link to={to} className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
    <div className="flex items-start justify-between">
      <div className="flex items-start space-x-4">
        <div className="p-3 bg-indigo-100 rounded-lg">
          <Icon className="h-6 w-6 text-indigo-600" />
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
          {metrics && (
            <div className="mt-3 flex items-center text-sm">
              <span className="font-medium text-indigo-600">{metrics.value}</span>
              <span className="ml-1.5 text-gray-500">{metrics.label}</span>
            </div>
          )}
        </div>
      </div>
      <ChevronRight className="h-5 w-5 text-gray-400" />
    </div>
  </Link>
);

const Dashboard = () => {
  const { user } = useAuth();

  const accountMetrics = {
    totalSearches: "124",
    activeLeads: "847",
    campaigns: "12",
    dataUsage: "65%"
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Welcome Section */}
      <div className="bg-gradient-to-r from-indigo-600 to-indigo-700 rounded-xl shadow-lg p-6 mb-8">
        <div className="flex items-center justify-between text-white">
          <div>
            <h1 className="text-2xl font-bold">Welcome back, {user?.name}</h1>
            <p className="mt-1 text-indigo-100">
              Your trade intelligence dashboard
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm text-indigo-100">Account Status</p>
            <p className="text-lg font-semibold">Premium</p>
          </div>
        </div>
      </div>

      {/* Account Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Total Searches"
          value={accountMetrics.totalSearches}
          icon={Search}
          trend={{ value: "12%", isPositive: true }}
        />
        <StatCard
          title="Active Leads"
          value={accountMetrics.activeLeads}
          icon={Target}
          trend={{ value: "8%", isPositive: true }}
        />
        <StatCard
          title="Active Campaigns"
          value={accountMetrics.campaigns}
          icon={Mail}
          trend={{ value: "5%", isPositive: true }}
        />
        <StatCard
          title="Data Usage"
          value={accountMetrics.dataUsage}
          icon={Database}
          trend={{ value: "3%", isPositive: false }}
        />
      </div>

      {/* Search Tools Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Search Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <ToolCard
            title="Buyer Search"
            description="Find potential buyers based on shipping data"
            icon={Search}
            to="/buyer-search"
            metrics={{ value: "156", label: "matches today" }}
          />
          <ToolCard
            title="Supplier Search"
            description="Discover reliable suppliers worldwide"
            icon={Package}
            to="/supplier-search"
            metrics={{ value: "89", label: "new suppliers" }}
          />
          <ToolCard
            title="Competitor Tracker"
            description="Monitor and analyze competitors"
            icon={Crosshair}
            to="/competitor-tracker"
            metrics={{ value: "34", label: "tracked companies" }}
          />
        </div>
      </div>

      {/* Analysis Tools Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Analysis Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <ToolCard
            title="Market Trends"
            description="Analyze market trends and patterns"
            icon={TrendingUp}
            to="/buyer-trends"
            metrics={{ value: "15", label: "markets analyzed" }}
          />
          <ToolCard
            title="HS Code Management"
            description="Manage and track HS codes"
            icon={Database}
            to="/hs-codes"
            metrics={{ value: "28", label: "active codes" }}
          />
          <ToolCard
            title="Search History"
            description="View and manage past searches"
            icon={Clock}
            to="/search-history"
            metrics={{ value: "45", label: "recent searches" }}
          />
        </div>
      </div>

      {/* Outreach Tools Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Outreach Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <ToolCard
            title="My Leads"
            description="Manage and organize your leads"
            icon={Target}
            to="/my-leads"
            metrics={{ value: "234", label: "active leads" }}
          />
          <ToolCard
            title="Email Campaigns"
            description="Create and manage outreach campaigns"
            icon={Mail}
            to="/buyer-outreach"
            metrics={{ value: "12", label: "active campaigns" }}
          />
          <ToolCard
            title="Settings"
            description="Configure account and preferences"
            icon={Settings}
            to="/settings"
          />
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Recent Activity</h2>
          <Link to="/search-history" className="text-sm text-indigo-600 hover:text-indigo-500">
            View all
          </Link>
        </div>
        <div className="space-y-4">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="flex items-center justify-between py-3 border-b border-gray-200 last:border-0">
              <div className="flex items-center">
                <div className="p-2 bg-indigo-100 rounded-lg">
                  <Search className="h-4 w-4 text-indigo-600" />
                </div>
                <div className="ml-4">
                  <p className="text-sm font-medium text-gray-900">Buyer search performed</p>
                  <p className="text-sm text-gray-500">HS Code: 8544.42</p>
                </div>
              </div>
              <span className="text-sm text-gray-500">2 hours ago</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;