import React, { useState } from 'react';
import { Plus, Search, Trash2, Eye, EyeOff } from 'lucide-react';
import SmtpModal from './SmtpModal';
import SmtpMetrics from './SmtpMetrics';

interface SmtpServer {
  id: string;
  title: string;
  email: string;
  createdAt: string;
  host: string;
  port: number;
  authType: 'none' | 'ssl' | 'tls';
  username?: string;
  timeout: number;
  sslVerification: boolean;
}

const SmtpList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<keyof SmtpServer>('createdAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [selectedServer, setSelectedServer] = useState<SmtpServer | null>(null);
  const [smtpServers, setSmtpServers] = useState<SmtpServer[]>([
    {
      id: '1',
      title: 'Primary SMTP',
      email: 'smtp@company.com',
      createdAt: '2024-03-15',
      host: 'smtp.company.com',
      port: 587,
      authType: 'tls',
      username: 'smtp@company.com',
      timeout: 30,
      sslVerification: true
    }
  ]);

  const metrics = {
    dailyLimit: 10000,
    dailyUsed: 8500,
    monthlyLimit: 250000,
    monthlyUsed: 180000,
    rateLimit: 120,
    currentRate: 95
  };

  const handleSort = (key: keyof SmtpServer) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(key);
      setSortOrder('asc');
    }
  };

  const filteredServers = smtpServers
    .filter(server => 
      server.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      server.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const compareValue = (a[sortBy] > b[sortBy]) ? 1 : -1;
      return sortOrder === 'asc' ? compareValue : -compareValue;
    });

  const handleAdd = () => {
    setSelectedServer(null);
    setIsModalOpen(true);
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Are you sure you want to delete this SMTP server?')) {
      setSmtpServers(servers => servers.filter(server => server.id !== id));
    }
  };

  const handleView = (server: SmtpServer) => {
    setSelectedServer(server);
    setIsModalOpen(true);
  };

  const handleSave = (server: Partial<SmtpServer>) => {
    if (selectedServer) {
      // Update existing server
      setSmtpServers(servers =>
        servers.map(s => s.id === selectedServer.id ? { ...s, ...server } : s)
      );
    } else {
      // Add new server
      const newServer = {
        ...server,
        id: Date.now().toString(),
        createdAt: new Date().toISOString().split('T')[0]
      } as SmtpServer;
      setSmtpServers(servers => [...servers, newServer]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="space-y-6">
      {/* SMTP Metrics */}
      <SmtpMetrics metrics={metrics} />

      {/* SMTP List */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900">SMTP Servers</h3>
            <p className="mt-1 text-sm text-gray-500">Manage your SMTP server configurations.</p>
          </div>
          <button
            onClick={handleAdd}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add SMTP
          </button>
        </div>

        <div className="flex items-center mb-4">
          <div className="relative flex-1">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search SMTP servers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {filteredServers.map((server) => (
              <li key={server.id} className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <h4 className="text-sm font-medium text-gray-900">{server.title}</h4>
                    <p className="mt-1 text-sm text-gray-500">{server.email}</p>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        {server.authType.toUpperCase()}
                      </span>
                      <span className="mx-2">•</span>
                      <span>{server.host}:{server.port}</span>
                    </div>
                  </div>
                  <div className="ml-4 flex items-center space-x-4">
                    <button
                      onClick={() => handleView(server)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(server.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <SmtpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        server={selectedServer}
      />
    </div>
  );
};

export default SmtpList;