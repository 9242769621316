import React from 'react';
import { Mail, Phone, Globe, Linkedin, MessageSquare } from 'lucide-react';

interface ContactSectionProps {
  supplier: {
    id: number;
  };
}

const ContactSection: React.FC<ContactSectionProps> = ({ supplier }) => {
  const handleContact = () => {
    // Implement contact functionality
  };

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">Contact Information</h2>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-gray-400 mr-3" />
              <div>
                <p className="text-sm text-gray-500">Email</p>
                <p className="text-sm font-medium text-gray-900">contact@supplier.com</p>
              </div>
            </div>
            <div className="flex items-center">
              <Phone className="h-5 w-5 text-gray-400 mr-3" />
              <div>
                <p className="text-sm text-gray-500">Phone</p>
                <p className="text-sm font-medium text-gray-900">+1 (555) 123-4567</p>
              </div>
            </div>
            <div className="flex items-center">
              <Globe className="h-5 w-5 text-gray-400 mr-3" />
              <div>
                <p className="text-sm text-gray-500">Website</p>
                <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  www.supplier.com
                </a>
              </div>
            </div>
            <div className="flex items-center">
              <Linkedin className="h-5 w-5 text-gray-400 mr-3" />
              <div>
                <p className="text-sm text-gray-500">LinkedIn</p>
                <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  Company Profile
                </a>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-sm font-medium text-gray-900 mb-4">Quick Contact</h3>
            <button
              onClick={handleContact}
              className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <MessageSquare className="h-4 w-4 mr-2" />
              Send Message
            </button>
            <p className="mt-2 text-xs text-gray-500 text-center">
              Typical response time: within 24 hours
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;