import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Star, Clock, Filter, Calendar, TrendingUp, CheckCircle, AlertCircle, Play, Pause, Edit, Eye, Plus } from 'lucide-react';
import StatCard from '../components/StatCard';
import Notification from '../components/Notification';

interface Campaign {
  id: number;
  name: string;
  launchDate: string;
  status: 'active' | 'paused' | 'draft' | 'completed';
  leads: number;
  responses: number;
  responseRate: string;
}

const BuyerOutreach = () => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [notification, setNotification] = useState<{
    type: 'success' | 'error' | 'info';
    message: string;
  } | null>(null);

  const [campaigns, setCampaigns] = useState<Campaign[]>([
    {
      id: 1,
      name: 'US Electronics Importers Q1',
      launchDate: '2024-03-01',
      status: 'active',
      leads: 156,
      responses: 42,
      responseRate: '26.9%'
    },
    {
      id: 2,
      name: 'German Manufacturing',
      launchDate: '2024-02-15',
      status: 'paused',
      leads: 89,
      responses: 28,
      responseRate: '31.5%'
    },
    {
      id: 3,
      name: 'Asian Suppliers Network',
      launchDate: '2024-03-10',
      status: 'completed',
      leads: 234,
      responses: 85,
      responseRate: '36.3%'
    }
  ]);

  const showNotification = (type: 'success' | 'error' | 'info', message: string) => {
    setNotification({ type, message });
    setTimeout(() => setNotification(null), 5000);
  };

  const handleStatusToggle = (campaignId: number) => {
    setCampaigns(prevCampaigns =>
      prevCampaigns.map(campaign => {
        if (campaign.id === campaignId && campaign.status !== 'completed') {
          const newStatus = campaign.status === 'active' ? 'paused' : 'active';
          showNotification(
            'success',
            `Campaign ${campaign.name} ${newStatus === 'active' ? 'resumed' : 'paused'} successfully`
          );
          return { ...campaign, status: newStatus };
        }
        return campaign;
      })
    );
  };

  const handleCreateCampaign = () => {
    navigate('/campaign/create');
  };

  const handleEdit = (campaignId: number) => {
    navigate(`/campaign/edit/${campaignId}`);
  };

  const handleView = (campaignId: number) => {
    navigate(`/campaign/${campaignId}`);
  };

  const getStatusBadgeColor = (status: Campaign['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'paused':
        return 'bg-yellow-100 text-yellow-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status: Campaign['status']) => {
    switch (status) {
      case 'active':
        return <Play className="h-3 w-3 mr-1" />;
      case 'paused':
        return <Pause className="h-3 w-3 mr-1" />;
      case 'completed':
        return <CheckCircle className="h-3 w-3 mr-1" />;
      default:
        return <Edit className="h-3 w-3 mr-1" />;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}

      <div className="mb-8 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Outreach Campaigns</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your outreach campaigns and track engagement
          </p>
        </div>
        <button
          onClick={handleCreateCampaign}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Campaign
        </button>
      </div>

      {/* Analytics Overview */}
      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard
            title="Total Campaigns"
            value="12"
            icon={Mail}
            trend={{ value: "2", isPositive: true }}
          />
          <StatCard
            title="Active Campaigns"
            value="5"
            icon={Play}
            trend={{ value: "1", isPositive: true }}
          />
          <StatCard
            title="Total Leads"
            value="1,247"
            icon={Star}
            trend={{ value: "12%", isPositive: true }}
          />
          <StatCard
            title="Response Rate"
            value="28.5%"
            icon={TrendingUp}
            trend={{ value: "5%", isPositive: true }}
          />
        </div>
      </div>

      {/* Campaign Management */}
      <div className="bg-white rounded-xl shadow-md">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Campaign Management</h2>
            <div className="flex items-center space-x-4">
              {/* Date Range Filter */}
              <select
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                className="rounded-md border-gray-300 text-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="all">All Time</option>
                <option value="week">Last 7 Days</option>
                <option value="month">Last 30 Days</option>
                <option value="quarter">Last Quarter</option>
              </select>

              {/* Status Filter */}
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="rounded-md border-gray-300 text-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="paused">Paused</option>
                <option value="completed">Completed</option>
                <option value="draft">Draft</option>
              </select>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Campaign Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Launch Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Leads
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Response Rate
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {campaigns.map((campaign) => (
                <tr key={campaign.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{campaign.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="h-4 w-4 mr-2" />
                      {campaign.launchDate}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(campaign.status)}`}>
                      {getStatusIcon(campaign.status)}
                      <span className="capitalize">{campaign.status}</span>
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {campaign.leads}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="w-24 bg-gray-200 rounded-full h-2 mr-2">
                        <div
                          className="bg-indigo-600 h-2 rounded-full"
                          style={{ width: campaign.responseRate }}
                        />
                      </div>
                      <span className="text-sm text-gray-500">{campaign.responseRate}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center space-x-3">
                      <button
                        onClick={() => handleView(campaign.id)}
                        className="text-gray-400 hover:text-gray-500"
                        title="View Campaign"
                      >
                        <Eye className="h-5 w-5" />
                      </button>
                      {campaign.status !== 'completed' && (
                        <>
                          <button
                            onClick={() => handleEdit(campaign.id)}
                            className="text-gray-400 hover:text-gray-500"
                            title="Edit Campaign"
                          >
                            <Edit className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleStatusToggle(campaign.id)}
                            className="text-gray-400 hover:text-gray-500"
                            title={campaign.status === 'active' ? 'Pause Campaign' : 'Resume Campaign'}
                          >
                            {campaign.status === 'active' ? (
                              <Pause className="h-5 w-5" />
                            ) : (
                              <Play className="h-5 w-5" />
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BuyerOutreach;