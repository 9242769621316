import { supabase } from '../config';
import { handleAuthError } from '../utils/errorHandling';
import type { AuthResponse } from '@supabase/supabase-js';
import type { SignupData } from '../types/auth';

const RATE_LIMIT_DELAY = 1000; // 1 second delay between attempts

export const signUp = async (data: SignupData): Promise<AuthResponse> => {
  try {
    // Add delay to help prevent rate limiting
    await new Promise(resolve => setTimeout(resolve, RATE_LIMIT_DELAY));

    const response = await supabase.auth.signUp({
      email: data.email,
      password: data.password,
      options: {
        data: {
          full_name: data.fullName,
          avatar_url: null
        },
        emailRedirectTo: `${window.location.origin}/verify-email`
      }
    });

    if (response.error) {
      throw response.error;
    }

    return response;
  } catch (error) {
    throw handleAuthError(error);
  }
};

export const createUserProfile = async (userId: string, data: SignupData) => {
  try {
    const { error } = await supabase
      .from('user_profiles')
      .insert([{
        user_id: userId,
        full_name: data.fullName,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        last_seen: new Date().toISOString()
      }]);

    if (error) throw error;
  } catch (error) {
    throw handleAuthError(error);
  }
};