import React, { useState } from 'react';
import { Search, Filter, Package, Link as LinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import HSCodeAutocomplete from '../components/HSCodeAutocomplete';
import BuyerResultsTable from '../components/BuyerResultsTable';
import BuyerProfile from '../components/BuyerProfile';
import FilterPanel from '../components/FilterPanel';

const BuyerSearch = () => {
  const [searchParams, setSearchParams] = useState({
    hsCode: '',
    country: '',
    startDate: '',
    endDate: '',
    quickFilters: [] as string[]
  });
  const [showProfile, setShowProfile] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState<any>(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const quickFilters = [
    { id: 'high-volume', label: 'High Volume', color: 'indigo' },
    { id: 'recurring', label: 'Recurring Buyers', color: 'green' },
    { id: 'new', label: 'New Importers', color: 'yellow' }
  ];

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchParams.hsCode || !searchParams.country) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
      return;
    }
    setHasSearched(true);
    setShowError(false);
  };

  const handleBuyerClick = (buyer: any) => {
    setSelectedBuyer(buyer);
    setShowProfile(true);
  };

  const toggleQuickFilter = (filterId: string) => {
    setSearchParams(prev => ({
      ...prev,
      quickFilters: prev.quickFilters.includes(filterId)
        ? prev.quickFilters.filter(id => id !== filterId)
        : [...prev.quickFilters, filterId]
    }));
  };

  if (showProfile && selectedBuyer) {
    return <BuyerProfile buyer={selectedBuyer} onBack={() => setShowProfile(false)} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8 flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Buyer Search</h1>
          <p className="mt-1 text-sm text-gray-500">
            Find potential buyers based on global trade data
          </p>
        </div>
        <Link
          to="/hs-codes"
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <Package className="h-4 w-4 mr-2" />
          Request HS Code
          <LinkIcon className="h-4 w-4 ml-2" />
        </Link>
      </div>

      <div className="bg-white rounded-xl shadow-md">
        <div className="p-6 border-b border-gray-200">
          <form onSubmit={handleSearch} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="col-span-1 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  HS Code or Product Description
                </label>
                <HSCodeAutocomplete
                  value={searchParams.hsCode}
                  onChange={(value) => setSearchParams({ ...searchParams, hsCode: value })}
                />
              </div>

              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                  Importing Country
                </label>
                <div className="relative">
                  <select
                    id="country"
                    className="appearance-none block w-full rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={searchParams.country}
                    onChange={(e) => setSearchParams({ ...searchParams, country: e.target.value })}
                  >
                    <option value="">All Countries</option>
                    <option value="US">United States</option>
                    <option value="CN">China</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date Range
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <input
                    type="date"
                    value={searchParams.startDate}
                    onChange={(e) => setSearchParams({ ...searchParams, startDate: e.target.value })}
                    className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  <input
                    type="date"
                    value={searchParams.endDate}
                    onChange={(e) => setSearchParams({ ...searchParams, endDate: e.target.value })}
                    className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div>

            {showError && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      Please select both an HS Code and Country to search.
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex items-center space-x-4">
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <Search className="h-4 w-4 mr-2" />
                  Search Buyers
                </button>
                <button
                  type="button"
                  onClick={() => setIsFilterOpen(true)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Filter className="h-4 w-4 mr-2" />
                  More Filters
                </button>
              </div>

              <div className="flex items-center space-x-4">
                <span className="text-sm text-gray-500">Quick Filters:</span>
                {quickFilters.map(filter => (
                  <button
                    key={filter.id}
                    onClick={() => toggleQuickFilter(filter.id)}
                    className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                      searchParams.quickFilters.includes(filter.id)
                        ? `bg-${filter.color}-600 text-white`
                        : `bg-${filter.color}-100 text-${filter.color}-700 hover:bg-${filter.color}-200`
                    }`}
                  >
                    {filter.label}
                  </button>
                ))}
              </div>
            </div>
          </form>
        </div>

        <div className="p-6">
          {hasSearched ? (
            <BuyerResultsTable 
              onBuyerClick={handleBuyerClick}
              selectedLeads={[]}
              onSelectedLeadsChange={() => {}}
              showDeleteButton={false}
            />
          ) : (
            <div className="text-center py-12">
              <Search className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No results to show</h3>
              <p className="mt-1 text-sm text-gray-500">
                Start by searching for buyers using an HS Code and Country
              </p>
            </div>
          )}
        </div>
      </div>

      <FilterPanel
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onApply={() => {
          setHasSearched(true);
          setIsFilterOpen(false);
        }}
        type="buyer"
      />
    </div>
  );
};

export default BuyerSearch;