import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  Ship, 
  ChevronDown, 
  Search, 
  TrendingUp, 
  Users,
  Settings,
  LogOut,
  Menu,
  X,
  Bell,
  Factory,
  Target,
  Mail,
  LayoutDashboard,
  Crosshair
} from 'lucide-react';

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navItems = [
    { label: 'Dashboard', path: '/dashboard', icon: LayoutDashboard },
    { label: 'Buyers', path: '/buyer-search', icon: Users },
    { label: 'Suppliers', path: '/supplier-search', icon: Factory },
    { label: 'Competitors', path: '/competitor-tracker', icon: Crosshair },
    { label: 'Trends', path: '/buyer-trends', icon: TrendingUp },
    { label: 'My Leads', path: '/my-leads', icon: Target },
    { label: 'Campaigns', path: '/buyer-outreach', icon: Mail }
  ];

  if (!isAuthenticated) return null;

  return (
    <nav className="fixed top-0 left-0 right-0 bg-indigo-600 shadow-lg z-50">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <div className="flex-shrink-0">
            <Link to="/dashboard" className="flex items-center space-x-2">
              <Ship className="h-8 w-8 text-white" />
              <span className="font-bold text-xl text-white">7Y App</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            {navItems.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
                    isActive
                      ? 'bg-indigo-700 text-white'
                      : 'text-white hover:bg-indigo-500'
                  }`}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {item.label}
                </Link>
              );
            })}
          </div>

          {/* User Menu */}
          <div className="hidden md:flex items-center space-x-4">
            <button className="text-white hover:text-indigo-100 relative">
              <Bell className="h-5 w-5" />
              <span className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full text-xs flex items-center justify-center text-white">
                3
              </span>
            </button>
            <Link to="/settings" className="text-white hover:text-indigo-100">
              <Settings className="h-5 w-5" />
            </Link>
            <button
              onClick={handleLogout}
              className="flex items-center space-x-1 px-3 py-2 rounded-md text-white hover:text-indigo-100"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white hover:text-indigo-100"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-indigo-600 border-t border-indigo-500">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navItems.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                    isActive
                      ? 'bg-indigo-700 text-white'
                      : 'text-white hover:bg-indigo-500'
                  }`}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {item.label}
                </Link>
              );
            })}

            {/* Mobile Settings and Logout */}
            <div className="border-t border-indigo-500 pt-2">
              <Link
                to="/settings"
                className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-indigo-500"
              >
                <Settings className="h-4 w-4 mr-2" />
                Settings
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-indigo-500"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;