import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Building2, MapPin, TrendingUp, Check, Download, Target, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import SaveLeadsModal from '../SaveLeadsModal';

interface CompetitorResultsTableProps {
  competitorType: string;
}

const CompetitorResultsTable: React.FC<CompetitorResultsTableProps> = ({ competitorType }) => {
  const [sortBy, setSortBy] = useState('marketShare');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompetitors, setSelectedCompetitors] = useState<number[]>([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 20;

  const demoData = Array(100).fill(null).map((_, index) => ({
    id: index + 1,
    name: `Competitor ${index + 1}`,
    country: ['United States', 'Germany', 'Japan', 'China'][index % 4],
    city: ['New York', 'Berlin', 'Tokyo', 'Shanghai'][index % 4],
    marketShare: (Math.random() * 15).toFixed(1),
    volume: Math.floor(Math.random() * 10000000),
    trend: Math.random() > 0.5 ? 'up' : 'down',
    trendValue: `${(Math.random() * 20).toFixed(1)}%`,
    rating: Math.floor(Math.random() * 2) + 4,
    verified: Math.random() > 0.5
  }));

  const totalPages = Math.ceil(demoData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = demoData.slice(startIndex, endIndex);

  const handleSelectAllToggle = () => {
    if (selectAllPages) {
      setSelectAllPages(false);
      setSelectedCompetitors([]);
    } else {
      const shouldSelectAll = selectedCompetitors.length !== currentData.length;
      if (shouldSelectAll) {
        if (window.confirm('Do you want to select all competitors across all pages?')) {
          setSelectAllPages(true);
          setSelectedCompetitors(demoData.map(c => c.id));
        } else {
          setSelectedCompetitors(currentData.map(c => c.id));
        }
      } else {
        setSelectedCompetitors([]);
      }
    }
  };

  const toggleCompetitorSelection = (competitorId: number) => {
    setSelectAllPages(false);
    setSelectedCompetitors(prev => 
      prev.includes(competitorId) 
        ? prev.filter(id => id !== competitorId)
        : [...prev, competitorId]
    );
  };

  const handleSaveLeads = () => {
    if (selectedCompetitors.length > 0) {
      setIsModalOpen(true);
    }
  };

  const exportToCSV = () => {
    const selectedData = demoData.filter(competitor => selectedCompetitors.includes(competitor.id));
    const dataToExport = selectedData.length > 0 ? selectedData : currentData;
    
    const headers = ['Company Name', 'Country', 'City', 'Market Share', 'Volume', 'Trend', 'Rating', 'Verified'];
    const csvContent = [
      headers.join(','),
      ...dataToExport.map(competitor => [
        competitor.name,
        competitor.country,
        competitor.city,
        competitor.marketShare,
        competitor.volume,
        competitor.trendValue,
        competitor.rating,
        competitor.verified
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'competitor_analysis.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        {selectedCompetitors.length > 0 ? (
          <div className="flex items-center space-x-4 bg-indigo-50 px-4 py-2 rounded-lg">
            <span className="text-sm text-indigo-700">
              {selectedCompetitors.length} competitors selected
              {selectAllPages && ' (across all pages)'}
            </span>
            <button
              onClick={handleSaveLeads}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Save as Leads
            </button>
          </div>
        ) : (
          <div />
        )}
        <button
          onClick={exportToCSV}
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="h-4 w-4 mr-2" />
          Export Analysis
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  checked={selectAllPages || selectedCompetitors.length === currentData.length}
                  onChange={handleSelectAllToggle}
                />
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Company
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Market Share
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Volume
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Trend
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentData.map((competitor) => (
              <tr key={competitor.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={selectedCompetitors.includes(competitor.id)}
                    onChange={() => toggleCompetitorSelection(competitor.id)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                        <Target className="h-5 w-5 text-indigo-600" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <Link
                        to={`/company/${competitor.id}`}
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      >
                        {competitor.name}
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 text-gray-400 mr-1" />
                    <div className="text-sm text-gray-900">{competitor.city}, {competitor.country}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {competitor.marketShare}%
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    ${(competitor.volume / 1000000).toFixed(2)}M
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {competitor.trend === 'up' ? (
                      <span className="inline-flex items-center text-green-600">
                        <ArrowUpRight className="h-4 w-4 mr-1" />
                        {competitor.trendValue}
                      </span>
                    ) : (
                      <span className="inline-flex items-center text-red-600">
                        <ArrowDownRight className="h-4 w-4 mr-1" />
                        {competitor.trendValue}
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {competitor.verified ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      <Check className="h-3 w-3 mr-1" />
                      Verified
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      New
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
              <span className="font-medium">{Math.min(endIndex, demoData.length)}</span> of{' '}
              <span className="font-medium">{demoData.length}</span> results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter(page => {
                  const distance = Math.abs(page - currentPage);
                  return distance === 0 || distance === 1 || page === 1 || page === totalPages;
                })
                .map((page, index, array) => {
                  if (index > 0 && array[index - 1] !== page - 1) {
                    return [
                      <span key={`ellipsis-${page}`} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>,
                      <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                          currentPage === page
                            ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                            : 'bg-white text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        {page}
                      </button>
                    ];
                  }
                  return (
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                        currentPage === page
                          ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                          : 'bg-white text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      {page}
                    </button>
                  );
                })}
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>

      <SaveLeadsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={(title) => {
          // Handle saving leads
          console.log('Saving leads:', { title, selectedCompetitors });
          setIsModalOpen(false);
          setSelectedCompetitors([]);
          setSelectAllPages(false);
        }}
      />
    </div>
  );
};

export default CompetitorResultsTable;