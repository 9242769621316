import React, { useState } from 'react';
import { BarChart2, Globe } from 'lucide-react';

interface MarketPresenceProps {
  supplier: {
    id: number;
  };
}

const MarketPresence: React.FC<MarketPresenceProps> = ({ supplier }) => {
  const [view, setView] = useState<'markets' | 'trends'>('markets');

  const markets = [
    { region: 'North America', share: '35%', growth: '+12%' },
    { region: 'Europe', share: '28%', growth: '+8%' },
    { region: 'Asia Pacific', share: '22%', growth: '+15%' },
    { region: 'Rest of World', share: '15%', growth: '+5%' }
  ];

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium text-gray-900">Market Presence</h2>
          <div className="flex space-x-2">
            <button
              onClick={() => setView('markets')}
              className={`px-3 py-1 text-sm rounded-md ${
                view === 'markets'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Globe className="h-4 w-4" />
            </button>
            <button
              onClick={() => setView('trends')}
              className={`px-3 py-1 text-sm rounded-md ${
                view === 'trends'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <BarChart2 className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {markets.map((market, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-sm font-medium text-gray-900">{market.region}</h3>
                <span className="text-sm text-green-600">{market.growth}</span>
              </div>
              <div className="flex items-center">
                <div className="flex-1">
                  <div className="h-2 bg-gray-200 rounded-full">
                    <div
                      className="h-2 bg-indigo-600 rounded-full"
                      style={{ width: market.share }}
                    />
                  </div>
                </div>
                <span className="ml-3 text-sm font-medium text-gray-900">{market.share}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 bg-gray-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-gray-900 mb-3">Key Markets Overview</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-500">Total Export Markets</span>
              <span className="font-medium text-gray-900">45 countries</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-500">Primary Market Focus</span>
              <span className="font-medium text-gray-900">North America, Europe</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-500">Year-over-Year Growth</span>
              <span className="font-medium text-green-600">+12.5%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPresence;