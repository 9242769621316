import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Save, Play, Copy } from 'lucide-react';
import CampaignSetup from '../components/campaign/CampaignSetup';
import LeadSelection from '../components/campaign/LeadSelection';
import SmtpConfig from '../components/campaign/SmtpConfig';
import SequenceBuilder from '../components/campaign/SequenceBuilder';
import CampaignPreview from '../components/campaign/CampaignPreview';
import Notification from '../components/Notification';

type Step = 'setup' | 'leads' | 'smtp' | 'sequence' | 'preview';

interface CampaignData {
  id: string;
  name: string;
  description?: string;
  status: 'active' | 'paused' | 'draft' | 'completed';
  selectedLeads: string[];
  smtpConfig: {
    accounts: Array<{
      id: string;
      percentage: number;
    }>;
  };
  sequence: Array<{
    id: string;
    subject: string;
    content: string;
    delay: number;
    status?: 'completed' | 'pending';
    scheduledDate?: string;
    scheduledTime?: string;
  }>;
}

const CampaignEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState<Step>('setup');
  const [notification, setNotification] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  // Mock campaign data - replace with actual API call
  const [campaignData, setCampaignData] = useState<CampaignData>({
    id: id || '',
    name: 'US Electronics Importers Q1',
    description: 'Campaign targeting US electronics importers',
    status: 'active',
    selectedLeads: ['1', '2', '3'],
    smtpConfig: {
      accounts: [
        { id: '1', percentage: 60 },
        { id: '2', percentage: 40 }
      ]
    },
    sequence: [
      {
        id: '1',
        subject: 'Introduction Email',
        content: 'Hello {{company_name}}, We noticed your recent imports...',
        delay: 0,
        status: 'completed',
        scheduledDate: '2024-03-15',
        scheduledTime: '09:00'
      },
      {
        id: '2',
        subject: 'Follow-up Email',
        content: 'Hi {{contact_name}}, I wanted to follow up...',
        delay: 3,
        status: 'completed'
      },
      {
        id: '3',
        subject: 'Final Follow-up',
        content: 'Hi {{contact_name}}, Just checking in one last time...',
        delay: 7,
        status: 'pending'
      }
    ]
  });

  useEffect(() => {
    if (campaignData.status === 'completed') {
      navigate('/buyer-outreach');
      return;
    }
  }, [campaignData.status, navigate]);

  const steps: Array<{ id: Step; label: string }> = [
    { id: 'setup', label: 'Campaign Setup' },
    { id: 'leads', label: 'Lead Selection' },
    { id: 'smtp', label: 'SMTP Configuration' },
    { id: 'sequence', label: 'Sequence Builder' },
    { id: 'preview', label: 'Preview & Launch' }
  ];

  const validateStep = () => {
    const newErrors: Record<string, string> = {};

    switch (currentStep) {
      case 'setup':
        if (!campaignData.name.trim()) {
          newErrors.name = 'Campaign name is required';
        }
        break;
      case 'leads':
        if (campaignData.selectedLeads.length === 0) {
          newErrors.leads = 'Select at least one lead';
        }
        break;
      case 'smtp':
        if (campaignData.smtpConfig.accounts.length === 0) {
          newErrors.smtp = 'Select at least one SMTP account';
        }
        const totalPercentage = campaignData.smtpConfig.accounts.reduce(
          (sum, account) => sum + account.percentage,
          0
        );
        if (totalPercentage !== 100) {
          newErrors.smtpPercentage = 'Total distribution must equal 100%';
        }
        break;
      case 'sequence':
        if (campaignData.sequence.length === 0) {
          newErrors.sequence = 'Add at least one email to the sequence';
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      const currentIndex = steps.findIndex(step => step.id === currentStep);
      if (currentIndex < steps.length - 1) {
        setCurrentStep(steps[currentIndex + 1].id);
      }
    }
  };

  const handleBack = () => {
    const currentIndex = steps.findIndex(step => step.id === currentStep);
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1].id);
    }
  };

  const handleSave = async (run: boolean = false) => {
    if (validateStep()) {
      try {
        // Save campaign logic here
        setNotification({
          type: 'success',
          message: 'Campaign updated successfully'
        });
        setTimeout(() => {
          navigate('/buyer-outreach');
        }, 1500);
      } catch (error) {
        setNotification({
          type: 'error',
          message: 'Failed to update campaign'
        });
      }
    }
  };

  const handleDuplicate = async () => {
    try {
      const newCampaign = {
        ...campaignData,
        id: undefined,
        name: `${campaignData.name} (Copy)`,
        status: 'draft',
        sequence: campaignData.sequence.map(step => ({
          ...step,
          status: 'pending'
        }))
      };
      // Save new campaign logic here
      setNotification({
        type: 'success',
        message: 'Campaign duplicated successfully'
      });
      setTimeout(() => {
        navigate('/buyer-outreach');
      }, 1500);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Failed to duplicate campaign'
      });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <button
          onClick={() => navigate('/buyer-outreach')}
          className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Campaigns
        </button>
        <div className="mt-4 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Edit Campaign</h1>
          <button
            onClick={handleDuplicate}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <Copy className="h-4 w-4 mr-2" />
            Duplicate Campaign
          </button>
        </div>
      </div>

      {notification && (
        <div className="mb-4">
          <Notification
            type={notification.type}
            message={notification.message}
            onClose={() => setNotification(null)}
          />
        </div>
      )}

      {/* Progress Steps */}
      <div className="mb-8">
        <nav aria-label="Progress">
          <ol className="flex items-center">
            {steps.map((step, index) => (
              <li
                key={step.id}
                className={`relative ${
                  index !== steps.length - 1 ? 'pr-8 sm:pr-20' : ''
                }`}
              >
                <div className="flex items-center">
                  <div
                    className={`relative flex h-8 w-8 items-center justify-center rounded-full ${
                      currentStep === step.id
                        ? 'bg-indigo-600 text-white'
                        : steps.findIndex(s => s.id === currentStep) > index
                        ? 'bg-indigo-500 text-white'
                        : 'bg-gray-200 text-gray-500'
                    }`}
                  >
                    <span className="text-sm">{index + 1}</span>
                  </div>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.label}
                  </span>
                </div>
                {index !== steps.length - 1 && (
                  <div className="absolute top-4 left-8 -ml-px h-0.5 w-full bg-gray-200" />
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {/* Step Content */}
      <div className="bg-white rounded-xl shadow-md">
        <div className="p-6">
          {currentStep === 'setup' && (
            <CampaignSetup
              data={campaignData}
              onChange={setCampaignData}
              errors={errors}
            />
          )}
          {currentStep === 'leads' && (
            <LeadSelection
              selectedLeads={campaignData.selectedLeads}
              onChange={(leads) => setCampaignData({ ...campaignData, selectedLeads: leads })}
              errors={errors}
            />
          )}
          {currentStep === 'smtp' && (
            <SmtpConfig
              config={campaignData.smtpConfig}
              onChange={(config) => setCampaignData({ ...campaignData, smtpConfig: config })}
              errors={errors}
            />
          )}
          {currentStep === 'sequence' && (
            <SequenceBuilder
              sequence={campaignData.sequence}
              onChange={(sequence) => setCampaignData({ ...campaignData, sequence })}
              errors={errors}
              readOnlySteps={campaignData.sequence.filter(step => step.status === 'completed').map(step => step.id)}
            />
          )}
          {currentStep === 'preview' && (
            <CampaignPreview campaign={campaignData} />
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-between">
          <button
            type="button"
            onClick={handleBack}
            disabled={currentStep === 'setup'}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
          >
            Previous
          </button>
          <div className="flex space-x-4">
            {currentStep === 'preview' ? (
              <>
                <button
                  type="button"
                  onClick={() => handleSave(false)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Save className="h-4 w-4 mr-2" />
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={() => handleSave(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <Play className="h-4 w-4 mr-2" />
                  Save & Run
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignEdit;