import React from 'react';
import { Package, CheckCircle, Clock } from 'lucide-react';
import StatCard from '../StatCard';

interface HSCodeMetricsProps {
  totalAllocation: number;
  usedCodes: number;
  availableCodes: number;
}

const HSCodeMetrics: React.FC<HSCodeMetricsProps> = ({
  totalAllocation,
  usedCodes,
  availableCodes
}) => {
  const usagePercentage = ((usedCodes / totalAllocation) * 100).toFixed(1);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <StatCard
        title="Total HS Code Allocation"
        value={totalAllocation.toString()}
        icon={Package}
        trend={{ value: "100%", isPositive: true }}
      />
      <StatCard
        title="Currently Used HS Codes"
        value={usedCodes.toString()}
        icon={CheckCircle}
        trend={{ value: `${usagePercentage}%`, isPositive: true }}
      />
      <StatCard
        title="Available HS Codes"
        value={availableCodes.toString()}
        icon={Clock}
        trend={{ value: `${((availableCodes / totalAllocation) * 100).toFixed(1)}%`, isPositive: true }}
      />
    </div>
  );
};

export default HSCodeMetrics;