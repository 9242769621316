import React from 'react';
import { Mail, Clock, AlertCircle } from 'lucide-react';

interface SmtpMetricsProps {
  metrics: {
    dailyLimit: number;
    dailyUsed: number;
    monthlyLimit: number;
    monthlyUsed: number;
    rateLimit: number;
    currentRate: number;
  };
}

const SmtpMetrics: React.FC<SmtpMetricsProps> = ({ metrics }) => {
  const getDailyPercentage = () => (metrics.dailyUsed / metrics.dailyLimit) * 100;
  const getMonthlyPercentage = () => (metrics.monthlyUsed / metrics.monthlyLimit) * 100;

  const getStatusColor = (percentage: number) => {
    if (percentage >= 90) return 'text-red-600';
    if (percentage >= 75) return 'text-yellow-600';
    return 'text-green-600';
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">SMTP Usage Metrics</h3>
        
        {/* Daily Usage */}
        <div className="space-y-4">
          <div>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-gray-400 mr-2" />
                <span className="text-sm font-medium text-gray-700">Daily Email Usage</span>
              </div>
              <span className={`text-sm font-medium ${getStatusColor(getDailyPercentage())}`}>
                {metrics.dailyUsed} / {metrics.dailyLimit}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`h-2 rounded-full ${
                  getDailyPercentage() >= 90 ? 'bg-red-500' :
                  getDailyPercentage() >= 75 ? 'bg-yellow-500' :
                  'bg-green-500'
                }`}
                style={{ width: `${getDailyPercentage()}%` }}
              />
            </div>
          </div>

          {/* Monthly Usage */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-gray-400 mr-2" />
                <span className="text-sm font-medium text-gray-700">Monthly Email Usage</span>
              </div>
              <span className={`text-sm font-medium ${getStatusColor(getMonthlyPercentage())}`}>
                {metrics.monthlyUsed} / {metrics.monthlyLimit}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`h-2 rounded-full ${
                  getMonthlyPercentage() >= 90 ? 'bg-red-500' :
                  getMonthlyPercentage() >= 75 ? 'bg-yellow-500' :
                  'bg-green-500'
                }`}
                style={{ width: `${getMonthlyPercentage()}%` }}
              />
            </div>
          </div>
        </div>

        {/* Warning Messages */}
        {getDailyPercentage() >= 90 && (
          <div className="mt-4 p-4 rounded-md bg-red-50">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-red-400" />
              <div className="ml-3">
                <p className="text-sm text-red-700">
                  You are approaching your daily email limit. Consider upgrading your plan to increase your limits.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SmtpMetrics;