import React from 'react';
import { DollarSign, Ship, PieChart, Globe, Package } from 'lucide-react';
import StatCard from '../StatCard';

interface SupplierStatisticsProps {
  stats: {
    totalExports: string;
    annualShipments: string;
    marketShare: string;
    destinations: string;
    productLines: string;
  };
}

const SupplierStatistics: React.FC<SupplierStatisticsProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
      <StatCard
        title="Annual Exports"
        value={stats.totalExports}
        icon={DollarSign}
        trend={{ value: "15%", isPositive: true }}
      />
      <StatCard
        title="Annual Shipments"
        value={stats.annualShipments}
        icon={Ship}
        trend={{ value: "8%", isPositive: true }}
      />
      <StatCard
        title="Market Share"
        value={stats.marketShare}
        icon={PieChart}
        trend={{ value: "5%", isPositive: true }}
      />
      <StatCard
        title="Export Destinations"
        value={stats.destinations}
        icon={Globe}
        trend={{ value: "3", isPositive: true }}
      />
      <StatCard
        title="Product Lines"
        value={stats.productLines}
        icon={Package}
        trend={{ value: "2", isPositive: true }}
      />
    </div>
  );
};

export default SupplierStatistics;