import React from 'react';
import { ArrowLeft, Building2, MapPin, Bookmark } from 'lucide-react';
import StatisticsOverview from '../components/company/StatisticsOverview';
import ProductRangeTable from '../components/company/ProductRangeTable';
import ShipmentHistoryTable from '../components/company/ShipmentHistoryTable';
import SupplierNetwork from '../components/company/SupplierNetwork';
import PersonnelTable from '../components/company/PersonnelTable';

interface CompanyProfileProps {
  company: {
    id: number;
    name: string;
    country: string;
    city: string;
    stats: {
      totalQuantity: string;
      totalShipments: string;
      totalSuppliers: string;
      origins: string;
      hsCodes: string;
    };
  };
  onBack: () => void;
}

const CompanyProfile: React.FC<CompanyProfileProps> = ({ company, onBack }) => {
  // Mock data for the tables
  const products = [
    { hsCode: '8544.42', quantity: '450,000', shipments: '324' },
    { hsCode: '8501.10', quantity: '280,000', shipments: '256' },
    { hsCode: '8536.90', quantity: '470,000', shipments: '276' }
  ];

  const shipments = [
    { date: '2024-03-15', hsCode: '8544.42', quantity: '15,000', country: 'China', id: '1' },
    { date: '2024-03-14', hsCode: '8501.10', quantity: '12,000', country: 'Germany', id: '2' },
    { date: '2024-03-13', hsCode: '8536.90', quantity: '18,000', country: 'Japan', id: '3' }
  ];

  const suppliers = [
    { name: 'Tech Components Ltd', hsCode: '8544.42', quantity: '120,000', percentage: '25%', rank: 1 },
    { name: 'Global Electronics', hsCode: '8501.10', quantity: '95,000', percentage: '20%', rank: 2 },
    { name: 'Asia Manufacturing', hsCode: '8536.90', quantity: '85,000', percentage: '18%', rank: 3 }
  ];

  const personnel = [
    { id: '1', name: 'John Smith', position: 'Procurement Manager', linkedin: 'john-smith' },
    { id: '2', name: 'Sarah Johnson', position: 'Supply Chain Director', linkedin: 'sarah-johnson' },
    { id: '3', name: 'Michael Chen', position: 'Import Specialist', linkedin: 'michael-chen' }
  ];

  const handleAddToLeads = () => {
    // Implement add to leads functionality
  };

  const handleViewShipmentDetails = (shipmentId: string) => {
    // Implement shipment details view
  };

  const handleAddToOutreach = (personId: string) => {
    // Implement add to outreach functionality
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={onBack}
        className="mb-6 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Results
      </button>

      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-md mb-8">
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="h-16 w-16 rounded-full bg-indigo-100 flex items-center justify-center">
                <Building2 className="h-8 w-8 text-indigo-600" />
              </div>
              <div className="ml-4">
                <h1 className="text-2xl font-bold text-gray-900">{company.name}</h1>
                <div className="flex items-center mt-1 text-gray-500">
                  <MapPin className="h-4 w-4 mr-1" />
                  {company.city}, {company.country}
                </div>
              </div>
            </div>
            <button
              onClick={handleAddToLeads}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Bookmark className="h-4 w-4 mr-2" />
              Add to My Leads
            </button>
          </div>
        </div>
      </div>

      {/* Statistics Overview */}
      <div className="mb-8">
        <StatisticsOverview stats={company.stats} />
      </div>

      {/* Product Range */}
      <div className="mb-8">
        <ProductRangeTable products={products} />
      </div>

      {/* Shipment History */}
      <div className="mb-8">
        <ShipmentHistoryTable
          shipments={shipments}
          onViewDetails={handleViewShipmentDetails}
        />
      </div>

      {/* Supplier Network */}
      <div className="mb-8">
        <SupplierNetwork suppliers={suppliers} />
      </div>

      {/* Personnel */}
      <div className="mb-8">
        <PersonnelTable
          personnel={personnel}
          onAddToOutreach={handleAddToOutreach}
        />
      </div>
    </div>
  );
};

export default CompanyProfile;