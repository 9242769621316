import React, { useState } from 'react';
import { Search, Filter, Users, Building2, Target, Database, AlertCircle, ChevronDown } from 'lucide-react';
import StatCard from '../StatCard';
import FilterPanel from '../FilterPanel';

interface LeadSelectionProps {
  selectedLeads: string[];
  onChange: (leads: string[]) => void;
  errors: Record<string, string>;
}

const LeadSelection: React.FC<LeadSelectionProps> = ({ selectedLeads, onChange, errors }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadType, setLeadType] = useState('');
  const [filters, setFilters] = useState({
    industry: '',
    country: '',
    minRevenue: '',
    maxRevenue: '',
    employeeRange: ''
  });

  const itemsPerPage = 10;

  // Mock data - replace with actual data source
  const leadGroups = [
    {
      id: '1',
      title: 'US Electronics Importers',
      companies: 12,
      dateSaved: '2024-03-15',
      type: 'buyer'
    },
    {
      id: '2',
      title: 'German Manufacturing',
      companies: 8,
      dateSaved: '2024-03-14',
      type: 'buyer'
    },
    {
      id: '3',
      title: 'Asian Suppliers',
      companies: 15,
      dateSaved: '2024-03-13',
      type: 'supplier'
    }
  ];

  const filteredGroups = leadType 
    ? leadGroups.filter(group => group.type === leadType)
    : leadGroups;

  const leads = selectedGroups.length > 0 ? Array.from({ length: 50 }, (_, i) => ({
    id: `lead-${i + 1}`,
    name: `Company ${i + 1}`,
    industry: ['Technology', 'Manufacturing', 'Healthcare', 'Retail'][i % 4],
    country: ['US', 'UK', 'DE', 'FR'][i % 4],
    revenue: Math.floor(Math.random() * 10000000),
    employees: Math.floor(Math.random() * 1000),
    lastContact: new Date(Date.now() - i * 24 * 60 * 60 * 1000).toLocaleDateString(),
    groupId: String(Math.floor(i / 7) + 1)
  })) : [];

  const totalLeads = leads.length;
  const selectedCount = selectedLeads.length;
  const availableGroups = filteredGroups.length;

  const handleGroupSelect = (groupId: string) => {
    const newSelection = selectedGroups.includes(groupId)
      ? selectedGroups.filter(id => id !== groupId)
      : [...selectedGroups, groupId];
    setSelectedGroups(newSelection);
  };

  const handleSelectAll = () => {
    if (selectedLeads.length === leads.length) {
      onChange([]);
    } else {
      onChange(leads.map(lead => lead.id));
    }
  };

  return (
    <div className="space-y-6">
      {/* Statistics Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Lead Groups"
          value={availableGroups.toString()}
          icon={Users}
          trend={{ value: "2", isPositive: true }}
        />
        <StatCard
          title="Total Companies"
          value={totalLeads.toString()}
          icon={Building2}
          trend={{ value: "12%", isPositive: true }}
        />
        <StatCard
          title="Selected"
          value={selectedCount.toString()}
          icon={Target}
          trend={{ value: `${((selectedCount / totalLeads) * 100).toFixed(1)}%`, isPositive: true }}
        />
        <StatCard
          title="Available Quota"
          value={`${((selectedCount / 500) * 100).toFixed(1)}%`}
          icon={Database}
          trend={{ value: "69%", isPositive: true }}
        />
      </div>

      {/* Lead Type Selection */}
      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <label htmlFor="leadType" className="block text-sm font-medium text-gray-700 mb-2">
          Select Lead Type
        </label>
        <div className="relative">
          <select
            id="leadType"
            value={leadType}
            onChange={(e) => {
              setLeadType(e.target.value);
              setSelectedGroups([]);
              onChange([]);
            }}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Select type</option>
            <option value="buyer">Buyers</option>
            <option value="supplier">Suppliers</option>
          </select>
        </div>
        {!leadType && errors.leads && (
          <p className="mt-2 text-sm text-red-600 flex items-center">
            <AlertCircle className="h-4 w-4 mr-1" />
            Please select a lead type
          </p>
        )}
      </div>

      {/* Lead Groups Dropdown */}
      <div className="relative">
        <button
          type="button"
          className={`w-full bg-white border rounded-md shadow-sm px-4 py-2 inline-flex justify-between items-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            !leadType ? 'opacity-50 cursor-not-allowed' : ''
          } ${errors.leads && selectedGroups.length === 0 ? 'border-red-300' : 'border-gray-300'}`}
          onClick={() => leadType && setIsGroupDropdownOpen(!isGroupDropdownOpen)}
          disabled={!leadType}
        >
          <span>
            {selectedGroups.length === 0
              ? 'Select Lead Groups'
              : `${selectedGroups.length} group${selectedGroups.length !== 1 ? 's' : ''} selected`}
          </span>
          <Users className="h-5 w-5 text-gray-400" />
        </button>

        {isGroupDropdownOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {filteredGroups.map((group) => (
              <div
                key={group.id}
                className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleGroupSelect(group.id)}
              >
                <input
                  type="checkbox"
                  checked={selectedGroups.includes(group.id)}
                  onChange={() => {}}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">{group.title}</p>
                  <p className="text-sm text-gray-500">
                    {group.companies} companies • Saved on {group.dateSaved}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {errors.leads && selectedGroups.length === 0 && (
          <p className="mt-2 text-sm text-red-600 flex items-center">
            <AlertCircle className="h-4 w-4 mr-1" />
            Please select at least one lead group
          </p>
        )}
      </div>

      {selectedGroups.length > 0 && (
        <>
          {/* Search and Filter Bar */}
          <div className="flex items-center justify-between">
            <div className="flex-1 max-w-lg">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search companies..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="ml-4 flex items-center space-x-4">
              <button
                type="button"
                onClick={() => setIsFilterPanelOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <Filter className="h-4 w-4 mr-2" />
                Filters
              </button>
            </div>
          </div>

          {/* Companies Table */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      checked={selectedLeads.length === leads.length && leads.length > 0}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Industry
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Revenue
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Employees
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {leads.map((lead) => (
                  <tr key={lead.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        checked={selectedLeads.includes(lead.id)}
                        onChange={() => {
                          if (selectedLeads.includes(lead.id)) {
                            onChange(selectedLeads.filter(id => id !== lead.id));
                          } else {
                            onChange([...selectedLeads, lead.id]);
                          }
                        }}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{lead.name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{lead.industry}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{lead.country}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">${(lead.revenue / 1000000).toFixed(2)}M</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{lead.employees}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      <FilterPanel
        isOpen={isFilterPanelOpen}
        onClose={() => setIsFilterPanelOpen(false)}
        onApply={() => setIsFilterPanelOpen(false)}
        type={leadType as 'buyer' | 'supplier'}
      />
    </div>
  );
};

export default LeadSelection;