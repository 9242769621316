import React, { useState, memo, useCallback } from 'react';
import { Plus, Trash2, GripVertical, AlertCircle, Calendar, Clock } from 'lucide-react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TipTapEditor from './TipTapEditor';
import AISequenceGenerator from './AISequenceGenerator';

interface EmailStep {
  id: string;
  subject: string;
  content: string;
  delay: number;
  scheduledDate?: string;
  scheduledTime?: string;
  step_name?: string;
  campaign_goal?: string;
  target_audience?: string;
  personalization_variables?: string[];
}

interface SequenceBuilderProps {
  sequence: EmailStep[];
  onChange: (sequence: EmailStep[]) => void;
  errors: Record<string, string>;
  readOnlySteps?: string[];
  campaignInfo?: {
    name: string;
    description?: string;
    industry?: string;
    region?: string;
    companySize?: string;
    goals?: string[];
  };
}

const SortableStep = memo(({ step, index, isSelected, onSelect, onRemove, isReadOnly }: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: step.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`flex items-center p-3 rounded-lg cursor-pointer ${
        isSelected
          ? 'bg-indigo-50 border-2 border-indigo-500'
          : 'bg-white border border-gray-200'
      }`}
      onClick={() => onSelect(step.id)}
      {...attributes}
      {...listeners}
    >
      <GripVertical className="h-4 w-4 text-gray-400 mr-2" />
      <div className="flex-1">
        <p className="text-sm font-medium text-gray-900">
          {step.subject || 'Untitled Email'}
        </p>
        <p className="text-xs text-gray-500">
          {index === 0 
            ? step.scheduledDate 
              ? `Scheduled for ${step.scheduledDate} at ${step.scheduledTime}`
              : 'Schedule start time'
            : `Wait ${step.delay} days`
          }
        </p>
      </div>
      {!isReadOnly && (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(step.id);
          }}
          className="ml-2 text-gray-400 hover:text-red-500"
        >
          <Trash2 className="h-4 w-4" />
        </button>
      )}
    </div>
  );
});

const SequenceBuilder: React.FC<SequenceBuilderProps> = ({
  sequence,
  onChange,
  errors,
  readOnlySteps = [],
  campaignInfo = {}
}) => {
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const selectedStepData = sequence.find(s => s.id === selectedStep);
  const isFirstStep = selectedStepData && sequence.indexOf(selectedStepData) === 0;
  const isReadOnly = selectedStep ? readOnlySteps.includes(selectedStep) : false;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAISequenceGenerated = (generatedSequence: EmailStep[]) => {
    onChange(generatedSequence);
    if (generatedSequence.length > 0) {
      setSelectedStep(generatedSequence[0].id);
    }
  };

  const addStep = useCallback(() => {
    const newStep: EmailStep = {
      id: `step-${Date.now()}`,
      subject: '',
      content: '',
      delay: 2
    };
    onChange([...sequence, newStep]);
    setSelectedStep(newStep.id);
  }, [sequence, onChange]);

  const updateStep = useCallback((id: string, updates: Partial<EmailStep>) => {
    onChange(sequence.map(step => 
      step.id === id ? { ...step, ...updates } : step
    ));
  }, [sequence, onChange]);

  const removeStep = useCallback((id: string) => {
    onChange(sequence.filter(step => step.id !== id));
    if (selectedStep === id) {
      setSelectedStep(null);
    }
  }, [sequence, onChange, selectedStep]);

  const handleDragEnd = useCallback((event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sequence.findIndex((step) => step.id === active.id);
      const newIndex = sequence.findIndex((step) => step.id === over.id);

      onChange(arrayMove(sequence, oldIndex, newIndex));
    }
  }, [sequence, onChange]);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Sequence Builder</h3>
        <p className="mt-1 text-sm text-gray-500">
          Create your email sequence with multiple steps or use AI to generate one
        </p>
      </div>

      <AISequenceGenerator
        campaignName={campaignInfo.name || ''}
        description={campaignInfo.description}
        targetAudience={{
          industry: campaignInfo.industry,
          region: campaignInfo.region,
          companySize: campaignInfo.companySize,
        }}
        goals={campaignInfo.goals}
        onSequenceGenerated={handleAISequenceGenerated}
      />

      <div className="grid grid-cols-3 gap-6">
        {/* Sequence Steps */}
        <div className="col-span-1 bg-gray-50 rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-sm font-medium text-gray-900">Email Steps</h4>
            <button
              type="button"
              onClick={addStep}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4 mr-1" />
              Add Step
            </button>
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={sequence.map(step => step.id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="space-y-2">
                {sequence.map((step, index) => (
                  <SortableStep
                    key={step.id}
                    step={step}
                    index={index}
                    isSelected={selectedStep === step.id}
                    onSelect={setSelectedStep}
                    onRemove={removeStep}
                    isReadOnly={readOnlySteps.includes(step.id)}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>

          {errors.sequence && (
            <p className="mt-2 text-sm text-red-600 flex items-center">
              <AlertCircle className="h-4 w-4 mr-1" />
              {errors.sequence}
            </p>
          )}
        </div>

        {/* Email Editor */}
        <div className="col-span-2">
          {selectedStepData ? (
            <div className="space-y-4">
              {isFirstStep && (
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h4 className="text-sm font-medium text-gray-900 mb-4 flex items-center">
                    <Calendar className="h-4 w-4 mr-2" />
                    Campaign Start Time
                  </h4>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="scheduledDate" className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="scheduledDate"
                        value={selectedStepData.scheduledDate || ''}
                        onChange={(e) => updateStep(selectedStep, { scheduledDate: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        disabled={isReadOnly}
                      />
                    </div>
                    <div>
                      <label htmlFor="scheduledTime" className="block text-sm font-medium text-gray-700">
                        Start Time
                      </label>
                      <input
                        type="time"
                        id="scheduledTime"
                        value={selectedStepData.scheduledTime || ''}
                        onChange={(e) => updateStep(selectedStep, { scheduledTime: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                  Subject Line
                </label>
                <input
                  type="text"
                  id="subject"
                  value={selectedStepData.subject}
                  onChange={(e) => updateStep(selectedStep, { subject: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  disabled={isReadOnly}
                />
              </div>

              {!isFirstStep && (
                <div>
                  <label htmlFor="delay" className="block text-sm font-medium text-gray-700">
                    Delay (days)
                  </label>
                  <input
                    type="number"
                    id="delay"
                    min="0"
                    value={selectedStepData.delay}
                    onChange={(e) => updateStep(selectedStep, { delay: Number(e.target.value) })}
                    className="mt-1 block w-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    disabled={isReadOnly}
                  />
                </div>
              )}

              <div>
                <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                  Email Content
                </label>
                <div className="mt-1">
                  <TipTapEditor
                    content={selectedStepData.content}
                    onChange={(content) => updateStep(selectedStep, { content })}
                    placeholder="Write your email content here..."
                    readOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full bg-gray-50 rounded-lg">
              <div className="text-center">
                <p className="text-gray-500">Select an email step to edit</p>
                {sequence.length === 0 && (
                  <button
                    type="button"
                    onClick={addStep}
                    className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add First Step
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SequenceBuilder;