import React from 'react';
import { Package, Settings, Clock } from 'lucide-react';

interface ProductCapabilitiesProps {
  supplier: {
    productionCapacity?: string;
  };
}

const ProductCapabilities: React.FC<ProductCapabilitiesProps> = ({ supplier }) => {
  const capabilities = [
    {
      title: 'Production Capacity',
      value: supplier.productionCapacity || '500,000 units/month',
      icon: Package
    },
    {
      title: 'Manufacturing Facilities',
      value: '3 factories',
      icon: Settings
    },
    {
      title: 'Lead Time',
      value: '15-30 days',
      icon: Clock
    }
  ];

  const products = [
    { category: 'Electronics', items: ['Circuit Boards', 'Power Supplies', 'Connectors'] },
    { category: 'Components', items: ['Capacitors', 'Resistors', 'Semiconductors'] },
    { category: 'Accessories', items: ['Cables', 'Adapters', 'Enclosures'] }
  ];

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">Product Capabilities</h2>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
          {capabilities.map((item, index) => {
            const Icon = item.icon;
            return (
              <div key={index} className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Icon className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-900">{item.title}</h3>
                    <p className="mt-1 text-sm text-gray-500">{item.value}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {products.map((category, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-4">
              <h3 className="text-sm font-medium text-gray-900 mb-3">{category.category}</h3>
              <ul className="space-y-2">
                {category.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center text-sm text-gray-600">
                    <span className="h-1.5 w-1.5 bg-indigo-600 rounded-full mr-2"></span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCapabilities;