import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Mail, Users, Server, Clock, Play, Pause, Edit } from 'lucide-react';
import StatCard from '../components/StatCard';

const CampaignView = () => {
  const { id } = useParams();

  // Mock campaign data - replace with actual data fetching
  const campaign = {
    id,
    name: 'US Electronics Importers Q1',
    status: 'active',
    startDate: '2024-03-15',
    sequence: [
      {
        id: '1',
        subject: 'Introduction Email',
        content: 'Hello {{company_name}}, We noticed your recent imports...',
        delay: 0,
        status: 'completed',
        stats: {
          sent: 156,
          opened: 89,
          replied: 12
        }
      },
      {
        id: '2',
        subject: 'Follow-up Email',
        content: 'Hi {{contact_name}}, I wanted to follow up...',
        delay: 3,
        status: 'completed',
        stats: {
          sent: 67,
          opened: 34,
          replied: 5
        }
      },
      {
        id: '3',
        subject: 'Final Follow-up',
        content: 'Hi {{contact_name}}, Just checking in one last time...',
        delay: 7,
        status: 'pending',
        stats: {
          sent: 0,
          opened: 0,
          replied: 0
        }
      }
    ],
    stats: {
      totalRecipients: 156,
      emailsSent: 223,
      uniqueOpens: 123,
      replies: 17
    },
    smtpConfig: [
      { server: 'SMTP 1', distribution: '60%', sent: 134 },
      { server: 'SMTP 2', distribution: '40%', sent: 89 }
    ]
  };

  const handleToggleStatus = () => {
    // Implement status toggle functionality
  };

  const getStepBorderColor = (status: string) => {
    return status === 'completed' ? 'border-green-500' : 'border-gray-200';
  };

  const getStepIconColor = (status: string) => {
    return status === 'completed' ? 'text-green-500' : 'text-gray-400';
  };

  const getStepNumberColor = (status: string) => {
    return status === 'completed' ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Link
        to="/buyer-outreach"
        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Campaigns
      </Link>

      {/* Header */}
      <div className="bg-white rounded-xl shadow-md mb-8">
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{campaign.name}</h1>
              <p className="mt-1 text-sm text-gray-500">
                Started on {campaign.startDate}
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => {}}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <Edit className="h-4 w-4 mr-2" />
                Edit Campaign
              </button>
              <button
                onClick={handleToggleStatus}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                  campaign.status === 'active' ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'
                }`}
              >
                {campaign.status === 'active' ? (
                  <>
                    <Pause className="h-4 w-4 mr-2" />
                    Pause Campaign
                  </>
                ) : (
                  <>
                    <Play className="h-4 w-4 mr-2" />
                    Resume Campaign
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Total Recipients"
          value={campaign.stats.totalRecipients.toString()}
          icon={Users}
          trend={{ value: "100%", isPositive: true }}
        />
        <StatCard
          title="Emails Sent"
          value={campaign.stats.emailsSent.toString()}
          icon={Mail}
          trend={{ value: `${((campaign.stats.emailsSent / (campaign.stats.totalRecipients * campaign.sequence.length)) * 100).toFixed(1)}%`, isPositive: true }}
        />
        <StatCard
          title="Unique Opens"
          value={campaign.stats.uniqueOpens.toString()}
          icon={Server}
          trend={{ value: `${((campaign.stats.uniqueOpens / campaign.stats.emailsSent) * 100).toFixed(1)}%`, isPositive: true }}
        />
        <StatCard
          title="Replies"
          value={campaign.stats.replies.toString()}
          icon={Clock}
          trend={{ value: `${((campaign.stats.replies / campaign.stats.emailsSent) * 100).toFixed(1)}%`, isPositive: true }}
        />
      </div>

      {/* Sequence Timeline */}
      <div className="bg-white rounded-xl shadow-md mb-8">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Sequence Timeline</h2>
        </div>
        <div className="p-6">
          <div className="relative">
            {campaign.sequence.map((step, index) => (
              <div key={step.id} className="flex items-start mb-8 last:mb-0">
                <div className={`flex items-center justify-center w-8 h-8 rounded-full ${getStepNumberColor(step.status)}`}>
                  {index + 1}
                </div>
                <div className="ml-4 flex-1">
                  <div className={`bg-white rounded-lg p-4 border-2 ${getStepBorderColor(step.status)}`}>
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-sm font-medium text-gray-900">{step.subject}</h3>
                      <span className="text-sm text-gray-500">
                        {index === 0 ? 'Sent immediately' : `Sent after ${step.delay} days`}
                      </span>
                    </div>
                    <p className="text-sm text-gray-700 mb-4">{step.content}</p>
                    <div className="grid grid-cols-3 gap-4">
                      <div className={`bg-gray-50 p-3 rounded-md border ${getStepBorderColor(step.status)}`}>
                        <p className="text-sm text-gray-500">Sent</p>
                        <p className={`text-lg font-semibold ${getStepIconColor(step.status)}`}>{step.stats.sent}</p>
                      </div>
                      <div className={`bg-gray-50 p-3 rounded-md border ${getStepBorderColor(step.status)}`}>
                        <p className="text-sm text-gray-500">Opened</p>
                        <p className={`text-lg font-semibold ${getStepIconColor(step.status)}`}>{step.stats.opened}</p>
                      </div>
                      <div className={`bg-gray-50 p-3 rounded-md border ${getStepBorderColor(step.status)}`}>
                        <p className="text-sm text-gray-500">Replied</p>
                        <p className={`text-lg font-semibold ${getStepIconColor(step.status)}`}>{step.stats.replied}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* SMTP Distribution */}
      <div className="bg-white rounded-xl shadow-md">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">SMTP Distribution</h2>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            {campaign.smtpConfig.map((smtp, index) => (
              <div key={index} className="flex items-center">
                <div className="w-32">
                  <p className="text-sm font-medium text-gray-900">{smtp.server}</p>
                </div>
                <div className="flex-1 mx-4">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-indigo-600 h-2.5 rounded-full"
                      style={{ width: smtp.distribution }}
                    ></div>
                  </div>
                </div>
                <div className="w-32 flex justify-between">
                  <span className="text-sm font-medium text-gray-900">{smtp.distribution}</span>
                  <span className="text-sm text-gray-500">{smtp.sent} sent</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignView;