import React from 'react';
import { Package, Truck, Users, Globe, FileText } from 'lucide-react';
import StatCard from '../StatCard';

interface StatisticsOverviewProps {
  stats: {
    totalQuantity: string;
    totalShipments: string;
    totalSuppliers: string;
    origins: string;
    hsCodes: string;
  };
}

const StatisticsOverview: React.FC<StatisticsOverviewProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
      <StatCard
        title="Total Quantity"
        value={stats.totalQuantity}
        icon={Package}
        trend={{ value: "15%", isPositive: true }}
      />
      <StatCard
        title="Total Shipments"
        value={stats.totalShipments}
        icon={Truck}
        trend={{ value: "8%", isPositive: true }}
      />
      <StatCard
        title="Total Suppliers"
        value={stats.totalSuppliers}
        icon={Users}
        trend={{ value: "5%", isPositive: true }}
      />
      <StatCard
        title="Number of Origins"
        value={stats.origins}
        icon={Globe}
        trend={{ value: "2", isPositive: true }}
      />
      <StatCard
        title="Number of HS Codes"
        value={stats.hsCodes}
        icon={FileText}
        trend={{ value: "1", isPositive: true }}
      />
    </div>
  );
};

export default StatisticsOverview;