import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Users, ArrowLeft, Tag } from 'lucide-react';
import BuyerResultsTable from '../components/BuyerResultsTable';
import CompanyProfile from './CompanyProfile';
import LeadSummary from '../components/leads/LeadSummary';
import BulkActions from '../components/leads/BulkActions';
import DeleteConfirmModal from '../components/leads/DeleteConfirmModal';

type LeadSource = 'competitor' | 'buyer' | 'supplier';

const LeadDetail = () => {
  const { id } = useParams();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Mock data - in real app, fetch based on id
  const leadGroup = {
    id: 1,
    title: 'US Electronics Importers',
    companies: 12,
    dateSaved: '2024-03-15',
    source: 'buyer' as LeadSource,
    searchCriteria: {
      hsCode: '8544.42',
      country: 'US',
    }
  };

  // Mock table data for calculating totals
  const tableData = [
    { id: '1', importValue: 6050000, shipments: 14 },
    { id: '2', importValue: 5650000, shipments: 32 },
    { id: '3', importValue: 2390000, shipments: 14 }
  ];

  const totalShipments = tableData.reduce((sum, item) => sum + item.shipments, 0);
  const totalImportValue = tableData.reduce((sum, item) => sum + item.importValue, 0);

  const getSourceBadgeColor = (source: LeadSource) => {
    switch (source) {
      case 'competitor':
        return 'bg-red-100 text-red-800';
      case 'buyer':
        return 'bg-green-100 text-green-800';
      case 'supplier':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getSourceLabel = (source: LeadSource) => {
    switch (source) {
      case 'competitor':
        return 'Competitor';
      case 'buyer':
        return 'Buyer';
      case 'supplier':
        return 'Supplier';
      default:
        return 'Unknown';
    }
  };

  const handleCompanySelect = (company: any) => {
    const enhancedCompany = {
      ...company,
      stats: {
        totalQuantity: '1.2M units',
        totalShipments: '856',
        totalSuppliers: '45',
        origins: '12',
        hsCodes: '8'
      }
    };
    setSelectedCompany(enhancedCompany);
  };

  const handleExportCSV = () => {
    const selectedData = tableData.filter(item => selectedLeads.includes(item.id));
    const csvContent = [
      ['Company', 'Import Value', 'Shipments'].join(','),
      ...selectedData.map(item => 
        [`Company ${item.id}`, item.importValue, item.shipments].join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `lead_group_${id}_export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    // Implement delete logic here
    setSelectedLeads([]);
    setShowDeleteConfirm(false);
  };

  if (selectedCompany) {
    return <CompanyProfile company={selectedCompany} onBack={() => setSelectedCompany(null)} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Link
        to="/my-leads"
        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to My Leads
      </Link>

      <div className="bg-white rounded-xl shadow-md">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                <Users className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <h1 className="text-2xl font-bold text-gray-900">{leadGroup.title}</h1>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getSourceBadgeColor(leadGroup.source)}`}>
                    <Tag className="h-3 w-3 mr-1" />
                    {getSourceLabel(leadGroup.source)}
                  </span>
                </div>
                <div className="mt-1 flex items-center space-x-4 text-sm text-gray-500">
                  <span>{leadGroup.companies} companies</span>
                  <span>•</span>
                  <span>Saved on {leadGroup.dateSaved}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <LeadSummary
              hsCode={leadGroup.searchCriteria.hsCode}
              country={leadGroup.searchCriteria.country}
              totalShipments={totalShipments}
              totalImportValue={totalImportValue}
            />
          </div>
        </div>

        <div className="p-6">
          {selectedLeads.length === tableData.length && (
            <BulkActions
              onExport={handleExportCSV}
              onDelete={handleDelete}
            />
          )}

          <BuyerResultsTable 
            onBuyerClick={handleCompanySelect}
            selectedLeads={selectedLeads}
            onSelectedLeadsChange={setSelectedLeads}
            showDeleteButton={selectedLeads.length === tableData.length}
          />
        </div>
      </div>

      <DeleteConfirmModal
        isOpen={showDeleteConfirm}
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
      />
    </div>
  );
};

export default LeadDetail;