import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';

interface InvoiceData {
  invoiceNumber: string;
  date: string;
  amount: number;
  description: string;
  status: string;
}

export const generateInvoicePDF = (data: InvoiceData) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // Add company logo and header
  doc.setFontSize(20);
  doc.setTextColor(66, 66, 66);
  doc.text('7Y Trade Analytics', pageWidth / 2, 20, { align: 'center' });

  // Add invoice details
  doc.setFontSize(12);
  doc.setTextColor(89, 89, 89);
  doc.text(`Invoice Number: ${data.invoiceNumber}`, 20, 40);
  doc.text(`Date: ${data.date}`, 20, 50);
  doc.text(`Status: ${data.status.toUpperCase()}`, 20, 60);

  // Add billing details
  doc.setFontSize(14);
  doc.setTextColor(66, 66, 66);
  doc.text('Bill To:', 20, 80);
  doc.setFontSize(12);
  doc.setTextColor(89, 89, 89);
  doc.text('Customer Name', 20, 90);
  doc.text('Company Name', 20, 100);
  doc.text('Address Line 1', 20, 110);
  doc.text('City, State, ZIP', 20, 120);

  // Add invoice items
  autoTable(doc, {
    startY: 140,
    head: [['Description', 'Amount']],
    body: [[data.description, `$${data.amount.toFixed(2)}`]],
    theme: 'striped',
    headStyles: {
      fillColor: [79, 70, 229],
      textColor: 255,
      fontSize: 12
    },
    styles: {
      fontSize: 11,
      textColor: 89
    }
  });

  // Add total
  const finalY = (doc as any).lastAutoTable.finalY || 140;
  doc.setFontSize(12);
  doc.setTextColor(66, 66, 66);
  doc.text('Total:', 140, finalY + 20);
  doc.text(`$${data.amount.toFixed(2)}`, 170, finalY + 20);

  // Add footer
  doc.setFontSize(10);
  doc.setTextColor(128, 128, 128);
  doc.text('Thank you for your business!', pageWidth / 2, finalY + 40, { align: 'center' });
  doc.text('For any questions, please contact support@7ytrade.com', pageWidth / 2, finalY + 50, { align: 'center' });

  // Save the PDF
  doc.save(`invoice-${data.invoiceNumber}.pdf`);
};