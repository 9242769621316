import React from 'react';

interface LeadSummaryProps {
  hsCode: string;
  country: string;
  totalShipments: number;
  totalImportValue: number;
}

const LeadSummary: React.FC<LeadSummaryProps> = ({
  hsCode,
  country,
  totalShipments,
  totalImportValue
}) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-500">HS Code:</span>
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
          {hsCode}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-500">Country:</span>
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
          {country}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-500">Total Shipments:</span>
        <span className="text-sm font-medium text-gray-900">{totalShipments}</span>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-500">Import Value:</span>
        <span className="text-sm font-medium text-gray-900">${(totalImportValue / 1000000).toFixed(2)}M</span>
      </div>
    </div>
  );
};

export default LeadSummary;