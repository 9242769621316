import { AuthError } from '@supabase/supabase-js';
import { toast } from 'react-hot-toast';

const RATE_LIMIT_MESSAGE = 'Too many attempts. Please wait a moment before trying again.';
const NETWORK_ERROR_MESSAGE = 'Network error. Please check your connection.';
const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

export const handleAuthError = (error: unknown) => {
  console.error('Auth error:', error);

  if (error instanceof AuthError) {
    const errorMessage = getAuthErrorMessage(error);
    toast.error(errorMessage);
    throw new Error(errorMessage);
  }

  if (error instanceof Error) {
    const errorMessage = error.message.includes('network') 
      ? NETWORK_ERROR_MESSAGE 
      : error.message || DEFAULT_ERROR_MESSAGE;
    
    toast.error(errorMessage);
    throw error;
  }

  toast.error(DEFAULT_ERROR_MESSAGE);
  throw new Error(DEFAULT_ERROR_MESSAGE);
};

const getAuthErrorMessage = (error: AuthError): string => {
  switch (error.status) {
    case 400:
      return 'Invalid email or password format';
    case 401:
      return 'Invalid credentials';
    case 403:
      return 'Email not verified';
    case 422:
      return 'Invalid credentials format';
    case 429:
      return RATE_LIMIT_MESSAGE;
    case 500:
      return 'Service temporarily unavailable. Please try again later';
    default:
      if (error.message?.includes('Email not confirmed')) {
        return 'Please verify your email address';
      }
      if (error.message?.includes('Invalid login credentials')) {
        return 'Invalid email or password';
      }
      if (error.message?.includes('rate limit')) {
        return RATE_LIMIT_MESSAGE;
      }
      return error.message || DEFAULT_ERROR_MESSAGE;
  }
};

export const isAuthError = (error: unknown): error is AuthError => {
  return error instanceof AuthError || 
    (error instanceof Error && '__isAuthError' in error);
};