import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryProvider } from './lib/supabase/providers/QueryProvider';
import { AuthProvider } from './lib/supabase/providers/AuthProvider';
import { Toaster } from 'react-hot-toast';
import AppRoutes from './routes';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryProvider>
        <AuthProvider>
          <AppRoutes />
          <Toaster 
            position="top-right"
            toastOptions={{
              duration: 4000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              success: {
                duration: 3000,
                iconTheme: {
                  primary: '#4ade80',
                  secondary: '#fff',
                },
              },
              error: {
                duration: 5000,
                iconTheme: {
                  primary: '#ef4444',
                  secondary: '#fff',
                },
              },
            }}
          />
        </AuthProvider>
      </QueryProvider>
    </BrowserRouter>
  );
};

export default App;