import axios from 'axios';
import { API_ENDPOINTS } from './endpoints';
import { APIError, handleAPIError } from './errors';
import type { 
  GenerateSequenceRequest,
  GenerateSequenceResponse,
  SequenceStep 
} from './types';

const validateSequenceResponse = (data: any): data is GenerateSequenceResponse => {
  return data && 
    Array.isArray(data.steps) && 
    data.steps.every((step: any) => 
      typeof step.subject === 'string' &&
      typeof step.content === 'string' &&
      typeof step.delay === 'number'
    );
};

const normalizeStep = (step: any, index: number): SequenceStep => ({
  step_number: step.step_number || index + 1,
  step_name: step.step_name || `Step ${index + 1}`,
  subject: step.subject,
  content: step.content,
  delay: step.delay,
  campaign_goal: step.campaign_goal || 'Not specified',
  target_audience: step.target_audience || 'All segments',
  personalization_variables: Array.isArray(step.personalization_variables)
    ? step.personalization_variables
    : ['{{company_name}}', '{{contact_name}}']
});

export const generateEmailSequence = async (
  params: GenerateSequenceRequest
): Promise<SequenceStep[]> => {
  if (!params.campaignName?.trim()) {
    throw new APIError('Campaign name is required');
  }

  try {
    const response = await axios.post(API_ENDPOINTS.AI.GENERATE_SEQUENCE, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    });

    if (!validateSequenceResponse(response.data)) {
      throw new APIError('Invalid response format from AI service');
    }

    return response.data.steps.map(normalizeStep);

  } catch (error) {
    return handleAPIError(error);
  }
};